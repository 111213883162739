import React, { useState, useEffect, useCallback } from "react";
import FormInputSource from "../../../Ui/Input/FormInputSource";
import TagsInput from "../../../Ui/Input/TagsInput";

const RssFeedInputLayout = ({ onFieldChange }) => {
  const [rssConfig, setRssConfig] = useState({
    rssurl: "",
    tags: [],
    description: "",
  });

  const handleFieldChange = useCallback(onFieldChange, [onFieldChange]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRssConfig((prevConfig) => ({ ...prevConfig, [name]: value }));
  };

  const setTags = (newTags) => {
    setRssConfig((prevConfig) => ({ ...prevConfig, tags: newTags }));
  };

  useEffect(() => {
    if (handleFieldChange) {
      handleFieldChange("rssurl", rssConfig);
    }
  }, [rssConfig, handleFieldChange]);

  return (
    <div className="flex flex-col p-5 bg-white inner-page-bg-input">
      <div className="mb-6 mt-3">
        <FormInputSource
          label="RSS URL"
          type="text"
          name="rssurl"
          value={rssConfig.rssurl}
          onChange={handleChange}
          className="w-full"
        />
      </div>

      <div className="gap-4 mb-2">
        <label className="block text-gray-700 text-sm font-normal px-1  pb-1 uppercase tracking-wider">
          Elements
        </label>
        <TagsInput
          tags={rssConfig.tags}
          setTags={setTags}
          className="w-full"
        />
      </div>

      <div className="mb-6 mt-3">
        <FormInputSource
          label="Description"
          type="text"
          name="description"
          value={rssConfig.description}
          onChange={handleChange}
          className="w-full"
        />
      </div>
    </div>
  );
};

export default RssFeedInputLayout;
