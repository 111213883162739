import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addAccount,
  setOauthToken,
} from "../../../../redux/slices/gmailAccountsSlice";
import GooglePickerComponent from "../GooglePickerComponent";
import { jwtDecode } from "jwt-decode";
import SelectInput from "../../../Ui/Select/SelectInput";
import FormInputSource from "../../../Ui/Input/FormInputSource";
import googleIcon from "../../../../assets/images/google-icon.png";
import googleDriveIcon from "../../../../assets/images/google-drive-icon.webp";

const OneNoteInputLayout = ({ onFieldChange }) => {
  const dispatch = useDispatch();
  const existingAccounts = useSelector(
    (state) => state.gmailAccounts.existingAccounts
  );
  const oauthToken = useSelector((state) => state.gmailAccounts.oauthToken);

  const [gmailConfig, setGmailConfig] = useState({
    connection: "",
    section: "",
    page: "",
    maxPages: "",
    description: "",
  });

  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const handleFieldChange = useCallback(onFieldChange, [onFieldChange]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGmailConfig((prevConfig) => ({ ...prevConfig, [name]: value }));
  };

  useEffect(() => {
    if (handleFieldChange) {
      handleFieldChange("Gmail", gmailConfig);
    }
  }, [gmailConfig, handleFieldChange]);

  const handleAccountSelection = (e) => {
    const { value } = e.target;
    setGmailConfig((prevConfig) => ({ ...prevConfig, connection: value }));
  };

  const handleGoogleLogin = () => {
    window.google.accounts.id.initialize({
      client_id:
        "508080835431-s9ovoa2d5lkf4i3m3glj8tf3rppbosdm.apps.googleusercontent.com",
      callback: handleCredentialResponse,
    });
    window.google.accounts.id.prompt(); // Display the One Tap prompt or the pop-up dialog
  };

  const handleCredentialResponse = (response) => {
    const userObject = jwtDecode(response.credential);
    dispatch(addAccount(userObject.email)); // Add the account to Redux
    dispatch(setOauthToken(response.credential)); // Store the token in Redux
  };

  const handleFileSelected = (fileId) => {
    console.log("Selected file ID:", fileId);
    // Handle the selected file ID as needed
  };

  const handleFileInputClick = () => {
    if (oauthToken) {
      setIsPickerOpen(true); // Set the picker to open
    }
  };

  return (
    <div className="flex flex-col p-5 bg-white inner-page-bg-input">
      <div className="mb-4 w-full w-2/3 ">
        <label className="block text-gray-700 text-sm font-normal  px-1 pt-2.5 pb-1 uppercase tracking-wider">
          Connection
        </label>
        {existingAccounts.length > 0 ? (
          <SelectInput
            className="mt-1 w-full p-2.5 h-11 border border-slate-300 bg-[#fff3f7]
            focus:outline-none rounded-md text-sm font-normal"
            name="connection"
            value={gmailConfig.connection}
            options={existingAccounts.map((account) => ({
              value: account,
              label: account,
            }))}
            onChange={handleAccountSelection}
          />
        ) : (
          <button
            type="button"
            onClick={handleGoogleLogin}
            className="w-full px-3.5 h-10 border border-[#e1e1e1] text-black flex items-center
            rounded-md text-base  bg-[#fafbff] place-content-center"
          >
            <img
              src={googleIcon}
              alt="icon"
              className="w-5 h-5 object-cover p-0.5 mx-1"
            />
            <span className="px-1">Connect to a New Account</span>
          </button>
        )}
      </div>

      {oauthToken && (
        <>
          <div className="flex flex-col mb-2 mt-6">
            <label className="text-gray-700 text-sm font-normal uppercase tracking-wider mb-1">
             NoteBook</label>
            <button
              type="button"
              onClick={handleFileInputClick}
              className="px-4 py-2.5 border bg-green-100 text-black rounded-md flex items-center place-content-center"
            
              >
                 <img src={googleDriveIcon} alt="icon" className="w-4 h-4 mx-2" />
                Select a File from Google Drive
            </button>
          </div>
          {isPickerOpen && (
            <GooglePickerComponent
              oauthToken={oauthToken}
              onFileSelected={(fileId) => {
                handleFileSelected(fileId);
                setIsPickerOpen(false); // Close the picker after selection
              }}
            />
          )}

          <div className=" gap-4 my-4">
            <label className="block text-gray-700 text-sm font-normal px-1 pt-2.5 pb-1 uppercase tracking-wider">
              Section
            </label>
            <SelectInput
              name="section"
              value={gmailConfig.section}
              options={[
                { value: "section1", label: "Section 1" },
                { value: "section2", label: "Section 2" },
              ]}
              onChange={handleChange}
              className="w-full"
            />
          </div>

          <div className="gap-4 my-4">
            <label className="block text-gray-700 text-sm font-normal  px-1 pt-2.5 pb-1 uppercase tracking-wider">
              Page
            </label>
            <SelectInput
              name="page"
              value={gmailConfig.page}
              options={[
                { value: "page1", label: "Page 1" },
                { value: "page2", label: "Page 2" },
              ]}
              onChange={handleChange}
              className="w-full"
            />
          </div>

          <div className=" gap-4 my-4">
            <label className="block text-gray-700 text-sm font-normal px-1 pt-2.5 pb-1 uppercase tracking-wider">
              Max No of Pages
            </label>
            <SelectInput
              name="maxPages"
              value={gmailConfig.maxPages}
              options={[
                { value: "5", label: "5 Pages" },
                { value: "10", label: "10 Pages" },
              ]}
              onChange={handleChange}
              className="w-full"
            />
          </div>

          <div className="mb-6 mt-3">
            <FormInputSource
              label="Description"
              type="text"
              name="description"
              value={gmailConfig.description}
              onChange={handleChange}
              className="w-full"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default OneNoteInputLayout;
