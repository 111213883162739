import React from "react";
import Modal from "../Ui/Dialogs/dialogs";
import FormInput from "../Common/FormInput/FormInput";

const CreateAiSequence = ({ isModalOpen, toggleModal }) => {
  return (
    <Modal isOpen={isModalOpen} toggle={toggleModal} title="Add Sequence">
      <p className="text-gray-700 text-center px-12 pt-1 pb-6 text-sm">
        You can view their statuses, create new workers, and perform various
        actions to maintain optimal performance.
      </p>

      <div className="p-4 md:px-12 md:py-1 flex flex-col gap-3">
        <div className="flex">
          <FormInput label="Sequence Name" type="text" required />
        </div>
        <div className="shadow rounded py-5">
          <label className="block text-gray-700 text-sm font-medium px-5">
            Sequence
          </label>

          <div className="w-full ">
            <div className="flex place-content-end w-full ">
              <button
                className="bg-blue-500	text-xs px-2 py-1.5 rounded-md shadow
            border flex items-center mx-2 w-fit text-white"
              >
                + Select Workflow
              </button>
            </div>

            <div className="flex place-content-between items-center pt-3 pb-2 border-b px-5">
              <strong className="text-sm	font-light	">First Workflow</strong>
              <button
                id="dropdownDefaultButton"
                data-dropdown-toggle="dropdown"
                className="flex -gap-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-5 "
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                  />
                </svg>
             



             
              </button>
            </div>

            <div className="flex place-content-between items-center pt-5 pb-3 border-b  px-5">
              <strong className="text-sm	font-light	">First Workflow</strong>
              <button
                id="dropdownDefaultButton"
                data-dropdown-toggle="dropdown"
                className="flex"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                  />
                </svg>
              </button>
            </div>

            <div className="flex place-content-between items-center pt-5 pb-3 border-b  px-5">
              <strong className="text-sm	font-light	">First Workflow</strong>
              <button
                id="dropdownDefaultButton"
                data-dropdown-toggle="dropdown"
                className="flex"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                  />
                </svg>
              </button>
            </div>

            <div className="flex place-content-between items-center pt-5 pb-3  px-5">
              <strong className="text-sm	font-light	">First Workflow</strong>
              <button
                id="dropdownDefaultButton"
                data-dropdown-toggle="dropdown"
                className="flex"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                  />
                </svg>
              </button>
            </div>

          </div>
        </div>

        <div className="w-full flex justify-end my-5">
          <button className="bg-orange-400 hover:bg-orange-600 text-sm text-white px-7 pt-1 pb-1.5 rounded-md shadow flex items-center mx-2">
            Save
          </button>
          <button
            className="bg-violet-50	hover:bg-violet-100 text-sm px-7 pt-1 pb-1.5 rounded-md shadow-sm
            border flex items-center mx-2"
            onClick={toggleModal}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateAiSequence;
