import React from "react";

const BasicDialog = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white w-full max-w-lg rounded-lg shadow-lg relative rounded-lg">
        <div className="flex justify-between items-center mb-2 bg-[#f1f4f7] px-2 rounded-t-lg">
          <h3 className="text-lg font-semibold text-start w-full py-5 px-1 text-gray-600 px-4">{title}</h3>
          <button
            onClick={onClose}
            className="text-3xl text-gray-600 hover:text-gray-900 relative 
            right-2 -top-1.5"
          >
            &times;
          </button>
        </div>
        <div>{children}</div>
        <div className="mt-8 mb-4 text-right px-8">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default BasicDialog;
