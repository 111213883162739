import React from 'react';

const Button = ({ onClick, children, className, icon }) => (
  <button
    type="button"
    onClick={onClick}
    className={`flex items-center justify-center rounded-full shadow 
       text-sm px-10 py-2.5 mb-2  ${className}`}
  >
    {icon && <img src={icon} alt="icon" className="w-5 h-5 object-cover p-0.5 mx-1" />}
    {children}
  </button>  
);

export default Button;