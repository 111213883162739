import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormInputSource from "../../../Ui/Input/FormInputSource";
import LabelSelector from "../../../Ui/Select/LabelSelector";
import {
  addAccount,
  setOauthToken,
} from "../../../../redux/slices/gmailAccountsSlice";
import { jwtDecode } from "jwt-decode";
import SelectInput from "../../../Ui/Select/SelectInput";
import googleIcon from "../../../../assets/images/google-icon.png";

const GmailInputLayout = ({ onFieldChange }) => {
  const dispatch = useDispatch();
  const existingAccounts = useSelector(
    (state) => state.gmailAccounts.existingAccounts
  );

  const [gmailConfig, setGmailConfig] = useState({
    connection: "",
    from: "",
    to: "",
    subject: "",
    description: "",
    labels: [],
  });

  const [availableLabels] = useState(["Work", "Personal", "Important", "Spam"]);

  const handleFieldChange = useCallback(onFieldChange, [onFieldChange]);

  useEffect(() => {
    if (handleFieldChange) {
      handleFieldChange("Gmail", gmailConfig);
    }
  }, [gmailConfig, handleFieldChange]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGmailConfig((prevConfig) => ({ ...prevConfig, [name]: value }));
  };

  const handleAccountSelection = (e) => {
    const { value } = e.target;
    setGmailConfig((prevConfig) => ({ ...prevConfig, connection: value }));
  };

  const handleGoogleLogin = () => {
    window.google.accounts.id.initialize({
      client_id:
        "508080835431-s9ovoa2d5lkf4i3m3glj8tf3rppbosdm.apps.googleusercontent.com",
      callback: handleCredentialResponse,
    });
    window.google.accounts.id.prompt(); // Display the One Tap prompt or the pop-up dialog
  };

  const handleCredentialResponse = (response) => {
    const userObject = jwtDecode(response.credential);
    dispatch(addAccount(userObject.email));
    dispatch(setOauthToken(response.credential)); // Dispatch oauthToken to the Redux store
  };

  const handleLabelChange = (selectedLabels) => {
    setGmailConfig((prevConfig) => ({ ...prevConfig, labels: selectedLabels }));
  };

  return (
    <div className=" flex flex-col p-5 bg-white inner-page-bg-input">
      <div className="mb-4 w-full w-2/3 ">
        <label className="block text-gray-700 text-sm font-normal px-1 pb-1 pt-2.5 uppercase tracking-wider">
          Connection
        </label>
        {existingAccounts.length > 0 ? (
          <SelectInput
            className="mt-1 w-full p-2.5 h-11 border border-slate-300 
                focus:outline-none focus:shadow-outline rounded-md text-sm font-medium shadow-sm"
            name="connection"
            value={gmailConfig.connection}
            options={existingAccounts.map((account) => ({
              value: account,
              label: account,
            }))}
            onChange={handleAccountSelection}
          />
        ) : (
          <button
            type="button"
            onClick={handleGoogleLogin}
            className="w-full px-3.5 h-10 border border-[#e1e1e1] text-black flex items-center
            rounded-md text-base  bg-[#fafbff] place-content-center"
          >
            <img
              src={googleIcon}
              alt="icon"
              className="w-5 h-5 object-cover p-0.5 mx-1"
            />
            <span className="px-1">Connect to a New Account</span>
          </button>
        )}
      </div>

      <div className="items-center  w-full flex flex-row gap-6">
        <FormInputSource
          label="From"
          type="email"
          name="from"
          value={gmailConfig.from}
          onChange={handleChange}
          required
          className="w-full w-2/3 gap-8"
        />
        <FormInputSource
          label="To"
          type="email"
          name="to"
          value={gmailConfig.to}
          onChange={handleChange}
          required
          className="w-full w-2/3  "
        />
      </div>
      <FormInputSource
        label="Subject"
        type="text"
        name="subject"
        value={gmailConfig.subject}
        onChange={handleChange}
        className="w-full  gap-4 items-center "
      />

      <div className="mb-2 gap-4 block ">
        <label className="block text-gray-700 text-sm font-normal px-1 pt-2.5 pb-1 uppercase tracking-wider">
          Label
        </label>
        <LabelSelector
          labels={availableLabels}
          selectedLabels={gmailConfig.labels}
          onLabelChange={handleLabelChange}
          className="w-full md:w-1/2 mt-1"
        />
      </div>

      <form className="my-3 gap-4 block ">
        <div className="flex gap-6 items-end">
          <div className="w-full">
            <div className="flex items-center gap-2 px-1">
              <input
                type="checkbox"
                id="dateWithin"
                className="form-checkbox w-5 h-5 cursor-pointer"
                defaultChecked
              />
              <label
                htmlFor="dateWithin"
                className="block text-gray-700 text-sm font-normal px-1 pt-1 pb-1 uppercase tracking-wider"
              >
                Date with-in
              </label>
            </div>

            <input
              type="number"
              className=" h-11 px-2 py-1 w-full border border-slate-300 focus:outline-none focus:shadow-outline rounded-md text-sm font-normal shadow-sm"
              defaultValue="1"
            />
          </div>
          <div className="w-full">
            <span className="block text-gray-700 text-sm font-normal px-1 pt-2 pb-1 uppercase tracking-wider">
              Day
            </span>
            <input
              type="date"
              className="py-1 h-11 px-2 border border-slate-300 focus:outline-none 
          focus:shadow-outline rounded-md text-sm font-normal shadow-sm w-full"
            />
          </div>
        </div>

        <div className="flex items-center py-8 px-2 gap-2">
          <input
            type="checkbox"
            id="hasAttachment"
            className="form-checkbox w-5 h-5 cursor-pointer"
            defaultChecked
          />
          <label
            htmlFor="hasAttachment"
            className="block text-gray-700 text-sm font-normal px-1 pt-1.5 pb-1 uppercase tracking-wider"
          >
            Has attachment
          </label>
        </div>

        <div className="flex items-start flex-col">
          <label
            htmlFor="maxEmails"
            className="block text-gray-700 text-sm font-normal px-1 pt-2.5 pb-1 uppercase tracking-wider"
          >
            Maximum no. of emails
          </label>
          <input
            type="number"
            id="maxEmails"
            className="w-[100%] px-2 py-1  py-1 h-11 px-2 border border-slate-300 focus:outline-none 
          focus:shadow-outline rounded-md text-sm font-normal shadow-sm"
            defaultValue="20"
          />
        </div>
      </form>

      <div className="mb-6 mt-3">
        <FormInputSource
          label="Description"
          type="text"
          name="description"
          value={gmailConfig.description}
          onChange={handleChange}
          className="w-full"
        />
      </div>
    </div>
  );
};

export default GmailInputLayout;
