import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import AiWorkFlowCreate from "./CreateAiWorkeFlow";
import {
  getAiWorkflows,
  deleteAiWorkflow,
  updateAiWorkflow,
} from "../../redux/slices/aiWorkflowsSlice";
import AlertDialog from "../Ui/Dialogs/AlertDialog";

const AiWorkflows = () => {
  const dispatch = useDispatch();
  const { workflows, status, error, deleteStatus, deleteError } = useSelector(
    (state) => state.aiWorkflows
  );
  const [checkedItems, setCheckedItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [workflowToDelete, setWorkflowToDelete] = useState(null);

  useEffect(() => {
    dispatch(getAiWorkflows()); // Fetch workflows on component mount
  }, [dispatch]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setCheckedItems(workflows.map((workflow) => workflow.id));
    } else {
      setCheckedItems([]);
    }
  };

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setCheckedItems([...checkedItems, id]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== id));
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const handleToggleChange = (id) => {
    const workflow = workflows.find((wf) => wf.id === id);
    if (workflow) {
      dispatch(
        updateAiWorkflow({
          id,
          name: workflow.name,
          lastRun: workflow.lastRun,
          run: !workflow.run,
        })
      );
    }
  };

  const handleDeleteClick = (workflow) => {
    setWorkflowToDelete(workflow);
    toggleDeleteModal(); // Open delete confirmation modal
  };

  const handleConfirmDelete = () => {
    if (workflowToDelete) {
      dispatch(deleteAiWorkflow(workflowToDelete.id));
      toggleDeleteModal(); // Close modal after deletion
    }
  };

  const handleRunSelected = () => {
    console.log("Run selected workflows:", checkedItems);
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-1 lg:w-full w-[80%]">
        <Navbar />
        <div className="p-3 md:p-6">
          <div className="relative overflow-x-auto">
            <div className="flex md:flex-row flex-col place-content-between mb-4">
              <div className="flex items-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-5 ml-1 mt-0.5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 6a7.5 7.5 0 1 0 7.5 7.5h-7.5V6Z"
                  ></path>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 10.5H21A7.5 7.5 0 0 0 13.5 3v7.5Z"
                  ></path>
                </svg>
                <div>
                  <h1 className="text-black font-bold text-xl text-start flex items-center">
                    My Workflows
                  </h1>
                  <p className="text-black text-sm">
                    Manage your AI workers efficiently. You can view their
                    statuses, create new workers.
                  </p>
                </div>
              </div>
              <div className="flex items-center place-content-end">
                <button
                  type="button"
                  onClick={toggleModal}
                  className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-1 
                  focus:outline-none focus:ring-blue-300 
                  rounded-lg text-sm pl-3 pr-4 py-2  
                  text-center m-2"
                >
                  + Create new
                </button>
                <button
                  type="button"
                  onClick={handleRunSelected}
                  className="flex items-center text-white bg-orange-400 hover:bg-orange-600 focus:ring-1 
                  focus:outline-none focus:ring-red-300 
                  rounded-lg text-sm pl-3 pr-4 py-2 
                  text-center m-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-4 mr-1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z"
                    />
                  </svg>
                  Run all selected
                </button>
              </div>
            </div>

            <div className="w-full shadow p-5 bg-white rounded-lg nth-color">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 border border-grey-50">
                <thead className="text-base text-gray-700 bg-[#def1f6]">
                  <tr>
                    <th className="px-1 py-2 md:px-6 md:py-3 font-normal w-[10%]">
                      <input
                        className="w-4 h-4 cursor-pointer"
                        type="checkbox"
                        onChange={handleSelectAll}
                        checked={checkedItems.length === workflows.length}
                      />
                    </th>
                    <th className="px-1 py-2 md:px-6 md:py-3 font-medium">
                      Workflow Name
                    </th>
                    <th className="px-1 py-2 md:px-6 md:py-3 font-medium">
                      Scheduled Runs
                    </th>
                    <th className="px-1 py-2 md:px-6 md:py-3 font-medium">
                      Last Runs
                    </th>
                  
                    <th className="px-1 py-2 md:px-6 md:py-3 font-medium">
                      Edit
                      <span className="sr-only">Edit</span>
                    </th>
                    <th className="px-1 py-2 md:px-6 md:py-3 font-medium">
                      Delete
                      <span className="sr-only">Delete</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {workflows.map((workflow) => (
                    <tr key={workflow.id} className="bg-white hover:bg-gray-50">
                      <td className="px-1 py-2 md:px-6 md:py-3">
                        <input
                          className="w-4 h-4 cursor-pointer"
                          type="checkbox"
                          checked={checkedItems.includes(workflow.id)}
                          onChange={(e) => handleCheckboxChange(e, workflow.id)}
                        />
                      </td>
                      <td className="px-1 py-2 md:px-6 md:py-3 font-medium text-gray-900 whitespace-nowrap">
                        <span className="bg-[#fff7f3] rounded-full px-2 py-0.5">
                          {workflow.name}
                        </span>
                      </td>
                      <td className="px-1 py-2 md:px-6 md:py-3 text-black">
                        <label className="inline-flex items-end cursor-pointer">
                          <input
                            type="checkbox"
                            className="sr-only peer"
                            checked={workflow.run}
                            onChange={() => handleToggleChange(workflow.id)}
                          />
                          <div className="relative w-11 h-6 bg-slate-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-green-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-green-200 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                          <span className="ml-3 text-sm font-medium text-gray-900">
                            {workflow.run ? "On" : "Off"}
                          </span>
                        </label>
                      </td>
                      <td className="px-1 py-2 md:px-6 md:py-3 font-medium text-gray-900 whitespace-nowrap">
                        <span className="bg-[#fff3c3] rounded-full px-2 py-0.5">
                          {workflow.lastRun}
                        </span>
                      </td>
                    
                      <td className="px-1 py-2 md:px-6 md:py-3 text-blue-500 w-16	">
                        <button onClick={toggleModal}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="size-7 text-green-400 hover:text-green-600 hover:bg-green-100 p-1 hover:rounded-full"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                            ></path>
                          </svg>
                        </button>
                      </td>
                      <td className="px-1 py-2 md:px-6 md:py-3 text-red-500 w-16	">
                        <button onClick={() => handleDeleteClick(workflow)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="size-7 text-red-400 hover:text-red-600 hover:bg-red-100 p-1 hover:rounded-full"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                            ></path>
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Delete Confirmation Dialog */}
          <AlertDialog
            isOpen={isDeleteModalOpen}
            onClose={toggleDeleteModal}
            title="Confirm Delete"
            content={`Are you sure you want to delete the workflow "${workflowToDelete?.name}"?`}
            onConfirm={handleConfirmDelete}
            onCancel={toggleDeleteModal}
            icon="warning" // Adjust the icon as needed
          />
        </div>
      </div>
      <AiWorkFlowCreate isModalOpen={isModalOpen} toggleModal={toggleModal} />
    </div>
  );
};

export default AiWorkflows;
