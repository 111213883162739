import { configureStore } from '@reduxjs/toolkit';
import aiWorkersReducer from './slices/aiWorkersSlice';
import gmailAccountsReducer from './slices/gmailAccountsSlice';
import gmailApiReducer from './slices/gmailApiSlice'; 
import usersReducer from './slices/usersSlice'; 
import aiWorkflowsReducer from './slices/aiWorkflowsSlice'; // Import the aiWorkflowsSlice

const store = configureStore({
  reducer: {
    aiWorkers: aiWorkersReducer,
    gmailAccounts: gmailAccountsReducer,
    gmailApi: gmailApiReducer, 
    users: usersReducer, 
    aiWorkflows: aiWorkflowsReducer, // Add aiWorkflowsSlice to the store
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export default store;