export const initialAiWorkers = [
    {
      id: 1,
      name: "Email Summarizer",
      instruction: "Lorem ipsum dolor sit amet consectetur adipiscing elit sed do"
    },
    {
      id: 2,
      name: "Table Extracter",
      instruction: "Lorem ipsum dolor sit amet consectetur adipiscing elit sed do"
    },
    {
      id: 3,
      name: "Image Descriptor",
      instruction: "Lorem ipsum dolor sit amet consectetur adipiscing elit sed do"
    }
  ];
  
  export const initialUsers = [
    {
      id: 1,
      name: "John Doe",
      email: "john.doe@example.com",
      plan: "Free", // Default plan
      sprintsUsed: "3/50" // Default sprints used
    },
    {
      id: 2,
      name: "Jane Smith",
      email: "jane.smith@example.com",
      plan: "Basic", // Default plan
      sprintsUsed: "10/50" // Default sprints used
    },
    {
      id: 3,
      name: "Michael Johnson",
      email: "michael.johnson@example.com",
      plan: "Premium", // Default plan
      sprintsUsed: "25/50" // Default sprints used
    }
  ];

  export const initialAiWorkflows = [
    {
      id: 1,
      name: "My First Workflow",
      run: true,
      lastRun: "5 hours ago",
    },
    {
      id: 2,
      name: "My Second Workflow",
      run: false,
      lastRun: "2 days ago",
    },
    {
      id: 3,
      name: "My Third Workflow",
      run: true,
      lastRun: "1 day ago",
    },
    {
      id: 4,
      name: "My Fourth Workflow",
      run: false,
      lastRun: "3 hours ago",
    },
  ];