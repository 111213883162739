import React, { useState } from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import CreateAiSequence from "./CreateAiSequence";

const AiSequence = () => {
  const [checkedItems, setCheckedItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setCheckedItems([1, 2, 3]); // Assuming these are the IDs of the items
    } 

    else {
      setCheckedItems([]);
    }

  };

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setCheckedItems([...checkedItems, id]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== id));
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [products, setProducts] = useState([
    {
      id: 1,
      name: "Sequences 1",
      run: true,
      lastrun: "5 hr ago",
    },
    {
      id: 2,
      name: "Sequences 2",
      run: false,
      lastrun: "5 hr ago",
    },
    {
      id: 3,
      name: "Sequences 3",
      run: true,
      lastrun: "5 hr ago",
    },
    {
      id: 4,
      name: "Sequences 4",
      run: true,
      lastrun: "5 hr ago",
    },
   
  ]);

  const handleToggleChange = (id) => {
    setProducts(
      products.map((product) =>
        product.id === id ? { ...product, run: !product.run } : product
      )
    );
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-1 lg:w-full w-[80%]"> 
        <Navbar />
        <div className="p-3 md:p-6">
          <div className="relative overflow-x-auto">
            <div className="flex md:flex-row flex-col place-content-between mb-4">
              <div className="flex items-start">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-5 mt-1 mr-0.5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
                  ></path>
                </svg>

                <div>
                  <h1 className="text-black font-bold text-xl text-start flex items-center">
                    My Sequences
                  </h1>
                  <p className="text-black text-sm">
                    Manage your AI Sequences efficiently. You can view their
                    statuses.
                  </p>
                </div>
                
              </div>

              <div className="flex items-center place-content-end">
                <button
                  type="button"
                  onClick={toggleModal}
                  className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-1 
                  focus:outline-none focus:ring-blue-300 
                  rounded-lg text-sm pl-3 pr-4 py-2 
                  text-center m-2"
                >
                  + Create new
                </button>
                <button
                  type="button"
                  className="flex items-center text-white bg-red-500 hover:bg-red-600 focus:ring-1 
                  focus:outline-none focus:ring-red-300 
                  rounded-lg text-sm pl-3 pr-4 py-2 
                  text-center m-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-4 mr-1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                    />
                  </svg>
                  All Delete
                </button>
                <button
                  type="button"
                  className="flex items-center text-white bg-orange-400 hover:bg-orange-600 focus:ring-1 
                  focus:outline-none focus:ring-red-300 
                  rounded-lg text-sm pl-3 pr-4 py-2 
                  text-center m-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-4 mr-1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z"
                    />
                  </svg>
                  Run all selected
                </button>
              </div>
            </div>

            <div className="w-full shadow p-5 bg-white rounded-lg nth-color">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 border border-grey-50">
                <thead className="text-base text-gray-700 bg-slate-200">
                  <tr>
                    <th className="px-1 py-2 md:px-6 md:py-3 font-normal w-[10%]">
                      <input
                        className="w-4 h-4"
                        type="checkbox"
                        onChange={handleSelectAll}
                      />
                    </th>
                    <th className="px-1 py-2 md:px-6 md:py-3 font-medium ">
                      Sequences Name
                    </th>
                    <th className="px-1 py-2 md:px-6 md:py-3 font-medium ">
                      Scheduled Runs
                    </th>
                    <th className="px-1 py-2 md:px-6 md:py-3 font-medium ">
                      Last Runs
                    </th>
                    <th className="px-1 py-2 md:px-6 md:py-3 font-medium">
                      Delete
                      <span className="sr-only">Delete</span>
                    </th>
                    <th className="px-1 py-2 md:px-6 md:py-3 font-medium ">
                      Edit
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product) => (
                    <tr key={product.id} className="bg-white hover:bg-gray-50">
                      <td className="px-1 py-2 md:px-6 md:py-3">
                        <input
                          className="w-4 h-4"
                          type="checkbox"
                          checked={checkedItems.includes(product.id)}
                          onChange={(e) => handleCheckboxChange(e, product.id)}
                        />
                      </td>

                      <td className="px-1 py-2 md:px-6 md:py-3 font-medium text-gray-900 whitespace-nowrap">
                        <span className="bg-[#edfad2] rounded-full px-2 py-0.5">
                          {product.name}
                        </span>
                      </td>

                      <td className="px-1 py-2 md:px-6 md:py-3 text-black">
                        <label className="inline-flex items-end cursor-pointer">
                          <input
                            type="checkbox"
                            className="sr-only peer"
                            checked={product.run}
                            onChange={() => handleToggleChange(product.id)}
                          />
                          <div className="relative w-11 h-6 bg-slate-200 peer-focus:outline-none peer-focus:ring-1 peer-focus:ring-green-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-green-200 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                          <span className="ml-3 text-sm font-medium text-gray-900">
                            {product.run ? "On" : "Off"}
                          </span>
                        </label>
                      </td>

                      <td className="px-1 py-2 md:px-6 md:py-3 font-medium text-gray-900 whitespace-nowrap">
                        <span className="bg-[#fff3c3] rounded-full px-2 py-0.5">
                          {product.lastrun}
                        </span>
                      </td>

                      <td className="px-1 py-2 md:px-6 md:py-3">
                        <button
                          className="font-medium text-red-400 hover:underline text-center"
                          onClick={() => alert("Delete function")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="size-4 ml-4"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                            />
                          </svg>
                        </button>
                      </td>

                      <td className="px-1 py-2 md:px-6 md:py-3">
                        <button
                          className="font-medium text-amber-500 "
                          onClick={() => alert("Edit function")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="size-4 ml-2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                            />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <CreateAiSequence isModalOpen={isModalOpen} toggleModal={toggleModal} />
    </div>
  );
};

export default AiSequence;
