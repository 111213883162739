import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import md5 from 'md5';
import { generateApiHeaders } from '../../utils/apiHeaders';

// Async Thunk for creating Gmail filter
export const createGmailFilter = createAsyncThunk(
  'gmailApi/createGmailFilter',
  async ({ apiKey, connection, from, to, subject, label, days, date, attach, maxnum, splitter, desc }, { rejectWithValue }) => {
    const timestamp = Math.floor(Date.now() / 1000).toString();
    const nonceStr = md5(timestamp);
    const headers = generateApiHeaders(timestamp, nonceStr, apiKey); // Generate API headers

       // Log request details for debugging
       console.log('Sending POST request to API', {
        url: 'https://ars1.hedder.com/be/createGmailFilter',
        data: { connection, from, to, subject, label, days, date, attach, maxnum, splitter, desc },
        headers
      });
      
    try {
      console.debug('Sending POST request to https://ars1.hedder.com/be/createGmailFilter');
      console.debug('Headers:', headers);
      console.debug('Payload:', { connection, from, to, subject, label, days, date, attach, maxnum, splitter, desc });

      const response = await axios.post(
        'https://ars1.hedder.com/be/createGmailFilter', // No trailing slash
        {
          connection,
          from,
          to,
          subject,
          label,
          days,
          date,
          attach,
          maxnum,
          splitter,
          desc,
        },
        { headers }
      );

      console.debug('API response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Request failed:', error); // Add detailed debugging for errors
      if (error.response) {
        // Server-side error (e.g., 4xx, 5xx responses)
        return rejectWithValue(`Error: ${error.response.status} - ${error.response.data}`);
      } else if (error.request) {
        // Network error or no response
        return rejectWithValue('Network error: No response received from the server');
      } else {
        // Other unknown errors
        return rejectWithValue(`Request error: ${error.message}`);
      }
    }
  }
);

// Slice for managing Gmail filter API state
const gmailApiSlice = createSlice({
  name: 'gmailApi',
  initialState: {
    filterResponse: {}, // Initialize as an empty object for easier UI handling
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createGmailFilter.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createGmailFilter.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.filterResponse = action.payload;
        console.debug('API response:', state.filterResponse);
      })
      .addCase(createGmailFilter.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload; // Now getting detailed error messages
        console.debug('Error occurred:', state.error);
      });
  },
});

export default gmailApiSlice.reducer;
