import React from "react";

const FormInput = ({ label, type, required, value, onChange }) => (
  <div className="mb-2 w-full">
    <label className="block text-gray-700 text-sm font-medium px-1">
      {label} {required && <span className="text-red-500">*</span>}
    </label>
    {type === "textarea" ? (
      <textarea
        value={value}
        onChange={onChange}
        required={required}
        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 
          leading-tight border-inherit text-sm
          focus:outline-none focus:shadow-outline shadow-sm"
      />
    ) : (
      <input 
        type={type} 
        value={value}

        onChange={onChange}
        required={required}
        className="w-full p-2.5 h-10 border border-slate-300 
         focus:outline-none focus:shadow-outline rounded-md text-sm font-normal shadow-sm"
      />
    )
    
    }
  </div>
);

export default FormInput;
