
import md5 from 'md5';

export const generateApiHeaders = (timestamp, nonceStr, apiKey) => {
  const signature = md5(`timestamp=${timestamp}&nonceStr=${nonceStr}&key=${apiKey}`).toUpperCase();

  return {
    'timestamp': timestamp,
    'nonceStr': nonceStr,
    'signature': signature,
    'Content-Type': 'application/json',
  };
};
