import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import CreateUser from "./CreateUser";
import EditUser from "./EditUser";
import {
  addUser,
  updateUser,
  deleteUser,
  getUsers,
} from "../../redux/slices/usersSlice";
import AlertDialog from "../Ui/Dialogs/AlertDialog";

const Users = () => {
  const dispatch = useDispatch();
  const { users, status, error } = useSelector((state) => state.users);
  const [checkedItems, setCheckedItems] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // State for delete confirmation modal
  const [currentUser, setCurrentUser] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null); // State for user selected for deletion
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (status === "idle") {
      dispatch(getUsers());
    }
  }, [status, dispatch]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setCheckedItems(users.map((user) => user.id));
    } else {
      setCheckedItems([]);
    }
  };

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setCheckedItems([...checkedItems, id]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== id));
    }
  };

  const toggleCreateModal = () => {
    setIsCreateModalOpen(!isCreateModalOpen);
  };

  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const handleSaveUser = (newUser) => {
    dispatch(addUser(newUser));
    toggleCreateModal();
  };

  const handleEditUser = (user) => {
    setCurrentUser(user);
    toggleEditModal();
  };

  const handleUpdateUser = (updatedUser) => {
    dispatch(updateUser(updatedUser));
    toggleEditModal();
  };

  const handleDeleteUser = () => {
    if (userToDelete) {
      dispatch(deleteUser(userToDelete.id));
    }
    toggleDeleteModal();
  };

  const handleDeleteClick = (user) => {
    setUserToDelete(user); // Set user to be deleted
    toggleDeleteModal(); // Open the delete confirmation modal
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-1 lg:w-full w-[80%]">
        <Navbar />
        <div className="p-3 md:p-6">
          {status === "loading" && <p>Loading...</p>}
          {status === "failed" && <p>{error}</p>}
          {status === "succeeded" && (
            <div className="relative overflow-x-auto">
              <div className="flex md:flex-row flex-col place-content-between mb-4">
                <div className="flex items-start ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-5 m-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
                    />
                  </svg>
                  <div>
                    <h1 className="text-black font-bold text-xl text-start flex items-center">
                      My Users
                    </h1>
                    <p className="text-black text-sm block w-full">
                      Manage your users efficiently. You can view their details
                      and create new users.
                    </p>
                  </div>
                </div>
                <div className="flex items-center place-content-end">
                  <div className="relative w-full">
                    <input
                      type="text"
                      placeholder="Search by name or email"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      className="border rounded-lg py-2 px-3 pr-10  w-full text-sm h-[38px]"
                    />
                    <div className="absolute inset-y-0 right-0	rounded-r-lg ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-5 mt-2 text-gray-300 w-8 "
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                        />
                      </svg>
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={toggleCreateModal}
                    className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-1 
                    focus:outline-none focus:ring-blue-300 rounded-lg text-sm pl-3 pr-4 py-2 
                    text-center m-2 w-44"
                  >
                    + Create new
                  </button>
                </div>
              </div>

              <div className="w-full shadow p-5 bg-white rounded-lg">
                <table className="w-full text-sm text-left text-black">
                  <thead className="text-xs text-black uppercase bg-slate-100">
                    <tr>
                      <th scope="col" className="px-1 py-2 md:px-6 md:py-3">
                        <input
                          className="w-4 h-4 cursor-pointer"
                          type="checkbox"
                          checked={checkedItems.length === filteredUsers.length}
                          onChange={handleSelectAll}
                        />
                      </th>
                      <th className="px-1 py-2 md:px-6 md:py-3 font-normal w-[5%]">
                        #
                      </th>
                      <th className="px-1 py-2 md:px-6 md:py-3 font-normal">
                        User ID
                      </th>
                      <th className="px-1 py-2 md:px-6 md:py-3 font-normal">
                        Name
                      </th>
                      <th className="px-1 py-2 md:px-6 md:py-3 font-normal">
                        Email
                      </th>
                      <th className="px-1 py-2 md:px-6 md:py-3 font-normal">
                        Plan
                      </th>
                      <th className="px-1 py-2 md:px-6 md:py-3 font-normal">
                        Sprints Used
                      </th>
                      <th className="px-1 py-2 md:px-6 md:py-3 font-normal">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUsers.map((user, index) => (
                      <tr
                        key={user.id}
                        className="bg-white border-b hover:bg-slate-200"
                      >
                        <td className="px-1 py-2 md:px-6 md:py-3">
                          <input
                            className="w-4 h-4 cursor-pointer"
                            type="checkbox"
                            checked={checkedItems.includes(user.id)}
                            onChange={(event) =>
                              handleCheckboxChange(event, user.id)
                            }
                          />
                        </td>
                        <td className="px-1 py-2 md:px-6 md:py-3">
                          {index + 1}
                        </td>
                        <td className="px-1 py-2 md:px-6 md:py-3">{user.id}</td>
                        <td className="px-1 py-2 md:px-6 md:py-3">
                          <span className="bg-[#b8dfff] rounded-full px-3 py-0.5 ">
                            {user.name}
                          </span>
                        </td>
                        <td className="px-1 py-2 md:px-6 md:py-3">
                          {user.email}
                        </td>
                        <td className="px-1 py-2 md:px-6 md:py-3">
                          {/* {user.plan} */}
                          <span
                            className={`rounded-full px-3 py-0.5 ${
                              user.plan === "Basic"
                                ? "bg-green-200 text-green-800"
                                : user.plan === "Premium"
                                ? "bg-red-100 text-red-800"
                                : user.plan === "Enterprise"
                                ? "bg-yellow-100 text-yellow-800"
                                : "bg-orange-100 text-orange-800" // default style
                            }`}
                          >
                            {user.plan}
                          </span>
                        </td>
                        <td className="px-1 py-2 md:px-6 md:py-3">
                          <span className=" rounded-full px-3 py-0.5 font-sans font-normal	">
                           {user.sprintsUsed}
                          </span>
                        </td>
                        <td className="px-1 py-2 md:px-6 md:py-3 flex gap-8">
                          <button
                            className="font-medium text-amber-500"
                            onClick={() => handleEditUser(user)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="size-7 text-green-400 hover:text-green-600 hover:bg-green-100 p-1 hover:rounded-full"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>
                          </button>

                          <button
                            className="font-medium  text-amber-500"
                            onClick={() => handleDeleteClick(user)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="size-7 text-red-400 hover:text-red-600 hover:bg-red-100 p-1 hover:rounded-full"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
      {isCreateModalOpen && (
        <CreateUser
          isModalOpen={isCreateModalOpen}
          toggleModal={toggleCreateModal}
          onSave={handleSaveUser}
        />
      )}
      {isEditModalOpen && currentUser && (
        <EditUser
          isModalOpen={isEditModalOpen}
          toggleModal={toggleEditModal}
          user={currentUser}
          onSave={handleUpdateUser}
        />
      )}
      {isDeleteModalOpen && userToDelete && (
        <AlertDialog
          isOpen={isDeleteModalOpen}
          onClose={toggleDeleteModal}
          title="Delete User"
          children={`Are you sure you want to delete user ${userToDelete.name}? This action cannot be undone.`}
          onConfirm={handleDeleteUser}
          onDiscard={toggleDeleteModal}
          confirmText="Delete"
          discardText="Cancel"
          confirmColor="bg-red-500"
        />
      )}
    </div>
  );
};

export default Users;
