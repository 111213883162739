import React, { useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BasicDialog from "./basic-dailog";
import SelectInput from "../Select/SelectInput";

const ScheduleDialog = ({
  isOpen,
  onClose,
  selectedSchedule,
  handleScheduleChange,
}) => {
  const [selectedOption, setSelectedOption] = useState(selectedSchedule);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedTime, setSelectedTime] = useState("");

  const handleOptionChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    handleScheduleChange(e); // pass the change to parent
  };

  const handleDateChange = (date) => {
    setSelectedDates([...selectedDates, date]);
  };

  const handleDateRemove = (dateToRemove) => {
    setSelectedDates(selectedDates.filter((date) => date !== dateToRemove));
  };

  return (
    <BasicDialog isOpen={isOpen} onClose={onClose} title="Set Schedule">
      <div className="px-8">
        <label className="block text-gray-500 text-base font-normal px-1 pt-2.5 pb-0.5 text-wider">
          Select Schedule
        </label>
        <SelectInput
          name="schedule"
          value={selectedOption}
          options={[
            { label: "Every day", value: "day" },
            { label: "Every Week", value: "weekly" },
            { label: "Specific Dates", value: "specificdate" },
          ]}
          onChange={handleOptionChange}
          placeholder="-- Select a Schedule --"
        />

        {/* Render layout based on selected option */}
        {selectedOption === "day" && (
          <div className="mt-2 ">
            <label className="block text-gray-500 text-base font-normal px-1 pt-2.5 pb-0.5 text-wider">
              Select Time
            </label>
            <input
              type="time"
              value={selectedTime}
              onChange={(e) => setSelectedTime(e.target.value)}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 
            appearance-none block w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-lg shadow-md text-gray-800 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 transition duration-300 ease-in-out   focus:border-indigo-500 sm:text-sm"
            />
          </div>
        )}

        {selectedOption === "weekly" && (
          <div className="mt-4 px-3">
            <label className="block text-gray-500 text-base font-normal px-1 pt-2.5 pb-0.5 text-wider">
              Select Days
            </label>
            <div className="flex flex-wrap mt-2 mb-4 mb-8  bg-[#fafbff] border border-[#e1e1e1] rounded-lg">
              {[
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
              ].map((day) => (
                <div key={day} className="flex items-center pt-4 px-4 mb-4">
                  <input
                    type="checkbox"
                    id={day}
                    name={day}
                    className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500  cursor-pointer"
                  />
                  <label
                    htmlFor={day}
                    className="ml-2 block text-xs text-gray-500 "
                  >
                    {day}
                  </label>
                </div>
              ))}
            </div>
            <div className="mt-4">
              <label className="block text-gray-500 text-base font-normal px-1 pt-2.5 pb-0.5 text-wider">
                Select Time
              </label>
              <input
                type="time"
                value={selectedTime}
                onChange={(e) => setSelectedTime(e.target.value)}
                className="appearance-none block w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-lg shadow-md text-gray-800 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 transition duration-300 ease-in-out"
              />
            </div>
          </div>
        )}

        {selectedOption === "specificdate" && (
          <div className="mt-4	">
          <div className="flex ">
          <div>
              <label className="block text-gray-500 text-base font-normal px-1 pt-2.5 pb-1.5 text-wider">
                Select Dates
              </label>
              <DatePicker
                selected={null}
                onChange={(date) => handleDateChange(date)}
                dateFormat="yyyy/MM/dd"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholderText="Click to select a date"
                inline
              />
            </div>
            <div className="mt-2 flex">
              {selectedDates.length > 0 && (
                <ul className="flex flex-wrap	items-center">
                  {selectedDates.map((date, index) => (
                    <li key={index} className="flex items-center px-3 bg-red-50 rounded-lg p-0.5 m-2">
                      <span className="text-sm text-gray-700 ">
                        {date.toLocaleDateString()}
                      </span>
                      <button
                        type="button"
                        onClick={() => handleDateRemove(date)}
                        className="ml-2 text-sm text-red-500 hover:text-red-700">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-4 cursor-pointer"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                          />
                        </svg>
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            </div>
            <div className=" w-full">
              <label className="block text-gray-500 text-base font-normal px-1 pt-2.5 pb-1 text-wider">
                Select Time
              </label>
              <input
                type="time"
                value={selectedTime}
                onChange={(e) => setSelectedTime(e.target.value)}
                className="mt-1 appearance-none block w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-lg text-gray-800 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 transition duration-300 ease-in-out"
              />
            </div>
          </div>
        )}
      </div>
    </BasicDialog>
  );
};

export default ScheduleDialog;
