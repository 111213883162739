import React, { useState } from "react";
import PropTypes from "prop-types";
import AlertDialog from "../Dialogs/AlertDialog";

const SourceBox = ({ heading, description, onClick, onDelete }) => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [workflowToDelete, setWorkflowToDelete] = useState(null);

  // Toggle the delete modal
  const toggleDeleteModal = () => {
    setDeleteModalOpen(!isDeleteModalOpen);
  };

  // Handle the delete confirmation
  const handleDeleteClick = () => {
    setWorkflowToDelete({ name: heading }); // Set the workflow to delete
    toggleDeleteModal(); // Open the delete modal
  };

  // Handle delete confirmation
  const handleConfirmDelete = () => {
    onDelete(); // Call the actual delete function
    toggleDeleteModal(); // Close the modal after deletion
  };

  return (
    <div>
      {/* The main SourceBox content */}
      <div
        className="flex flex-col w-full bg-white mb-4 rounded shadow-[5px_15px_10px_-15px_rgba(0,0,0,0.3)]
        cursor-pointer transition-transform duration-200 hover:shadow-xl hover:-translate-y-1 
        border-l-rose-300 border-l-4 source-box"
        onClick={onClick}
      >
        <div className="flex flex-col border pl-4 pb-6 pr-3 pt-2 rounded-r-lg">
          <div className="flex place-content-end w-full gap-3 h-6">
            {/* Edit Icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-[19px] h-[19px] text-gray-400 hover:text-pink-600 hover:font-bold"
            >
              <path
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>

            {/* Delete Icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-[19px] h-[19px] text-gray-400 hover:text-pink-600 hover:font-bold cursor-pointer"
              onClick={(e) => {
                e.stopPropagation(); // Prevents the box click event from firing
                handleDeleteClick(); // Open the delete confirmation modal
              }}
            >
              <path
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          </div>
          <h2 className="text-sm tracking-wider text-slate-400 uppercase font-semibold pr-1">
            {heading}
          </h2>
          <p className="text-base mt-1 w-full pr-1">{description}</p>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && (
        <AlertDialog
          isOpen={isDeleteModalOpen}
          onClose={toggleDeleteModal}
          title="Confirm Delete"
          content={`Are you sure you want to delete the workflow "${workflowToDelete?.name}"?`}
          onConfirm={handleConfirmDelete}
          onCancel={toggleDeleteModal}
          icon="warning" // Adjust the icon as needed
        />
      )}
    </div>
  );
};

SourceBox.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onDelete: PropTypes.func.isRequired, // Ensure delete functionality is passed
};

export default SourceBox;
