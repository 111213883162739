import React, { useState } from "react";

const TagEditor = ({ onTagsChange }) => {
  const [tags, setTags] = useState([{ startTag: "", endTag: "" }]);

  const handleTagChange = (index, field, value) => {
    const newTags = tags.map((tag, i) =>
      i === index ? { ...tag, [field]: value } : tag
    );
    setTags(newTags);
    onTagsChange(newTags);
  };

  const addTag = () => {
    setTags([...tags, { startTag: "", endTag: "" }]);
  };

  const removeTag = (index) => {
    const newTags = tags.filter((_, i) => i !== index);
    setTags(newTags);
    onTagsChange(newTags);
  };

  return (
    <div className="flex flex-col gap-4">
      {tags.map((tag, index) => (
        <div key={index} className="flex items-center gap-3">
          <input
            type="text"
            placeholder="Start Tag"
            value={tag.startTag}
            onChange={(e) => handleTagChange(index, "startTag", e.target.value)}
            className="form-input w-full border border-gray-300 p-2.5 rounded-md text-sm font-normal shadow-sm"
          />
          <input
            type="text"
            placeholder="End Tag"
            value={tag.endTag}
            onChange={(e) => handleTagChange(index, "endTag", e.target.value)}
            className="form-input w-full border border-gray-300 p-2.5 rounded-md text-sm font-normal shadow-sm"
          />
          <button
            type="button"
            onClick={() => removeTag(index)}
            className="text-red-500 px-1 rounded"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          </button>
          {/* Show add tag button only on the last tag row */}
          {index === tags.length - 1 && (
            <button
              type="button"
              onClick={addTag}
              className="text-green-600 py-2 "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="indent-3	size-6 rounded-3xl text-white bg-green-500 p-1 shadow-xl border border-gray-400"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

export default TagEditor;
