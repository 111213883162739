import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addWorker, getAiWorkers } from '../../redux/slices/aiWorkersSlice';
import AiWorkersCreate from './CreateAiWorker';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';

const AiWorkers = () => {
  const dispatch = useDispatch();
  const { workers, status, error } = useSelector((state) => state.aiWorkers);
  const [checkedItems, setCheckedItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(getAiWorkers());
    }  
  }, [status, dispatch]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setCheckedItems(workers.map(worker => worker.id));
    } else {
      setCheckedItems([]);
    }
  };

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setCheckedItems([...checkedItems, id]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== id));
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSaveWorker = (newWorker) => {
    dispatch(addWorker(newWorker));
    toggleModal();
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-1 lg:w-full w-[80%]">
        <Navbar />
        <div className="p-3 md:p-6">
          {status === 'loading' && <p>Loading...</p>}
          {status === 'failed' && <p>{error}</p>}
          {status === 'succeeded' && ( 
            <div className="relative overflow-x-auto"> 
              <div className="flex md:flex-row flex-col place-content-between mb-4">
               
                <div className="flex items-start">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5 m-1">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
                  </svg>
                  <div>
                    <h1 className="text-black font-bold text-xl text-start flex items-center">My AI Workers</h1>
                    <p className="text-black text-sm">Manage your AI workers efficiently. You can view their statuses, create new workers.</p>
                  </div>
                 </div>

                <div className="flex items-center place-content-end">
                 <button
                    type="button"
                    onClick={toggleModal}
                    className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-1 
                    focus:outline-none focus:ring-blue-300 rounded-lg text-sm pl-3 pr-4 py-2 
                    text-center m-2">+ Create new
                  </button>

                  <button
                    type="button"
                    className="flex items-center text-white bg-red-500 hover:bg-red-600 focus:ring-1 
                    focus:outline-none focus:ring-red-300 rounded-lg text-sm pl-3 pr-4 py-2 
                    text-center m-2"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-4 mr-1">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                    </svg>
                    All Delete
                  </button> 
               
                </div>   
              </div>

              <div className="w-full shadow p-5 bg-white rounded-lg">
                <table className="w-full text-sm text-left text-black">
                  <thead className="text-xs text-black uppercase bg-slate-100">
                    <tr>
                      <th scope="col" className="px-1 py-2 md:px-6 md:py-3">
                        <input
                          className="w-4 h-4"
                          type="checkbox"  
                          checked={checkedItems.length === workers.length}
                          onChange={handleSelectAll}
                        />
                      </th>
                      <th className="px-1 py-2 md:px-6 md:py-3 font-normal w-[5%]">#</th>
                      <th className="px-1 py-2 md:px-6 md:py-3 font-normal">Worker ID</th>
                      <th className="px-1 py-2 md:px-6 md:py-3 font-normal">Name</th>
                      <th className="px-1 py-2 md:px-6 md:py-3 font-normal">Instruction</th>
                      <th className="px-1 py-2 md:px-6 md:py-3 font-normal">Status</th>
                      <th className="px-1 py-2 md:px-6 md:py-3 font-normal">Actions</th>
                    </tr>
                  </thead>   
                  <tbody> 
                    {workers.map((worker, index) => (
                      <tr key={worker.id} className="bg-white border-b hover:bg-slate-200">
                        <td className="px-1 py-2 md:px-6  md:py-3">
                          <input 
                            className="w-4 h-4"
                            type="checkbox"
                            checked={checkedItems.includes(worker.id)}
                            onChange={(event) => handleCheckboxChange(event, worker.id)}
                          />
                        </td>
                        <td className="px-1 py-2 md:px-6 md:py-3">{index + 1}</td>
                        <td className="px-1 py-2 md:px-6 md:py-3">{worker.id}</td>
                        <td className="px-1 py-2 md:px-6 md:py-3">{worker.name}</td>
                        <td className="px-1 py-2 md:px-6 md:py-3">{worker.instruction}</td>
                        <td className="px-1 py-2 md:px-6 md:py-3">{worker.status}</td>
                        <td className="px-1 py-2 md:px-6 md:py-3 flex gap-8">
                        <button className="font-medium text-amber-500 ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="size-4 ml-2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                              ></path>
                            </svg>
                          </button>
                          <button className="font-medium text-red-400 hover:underline text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="size-4"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody> 
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
      {isModalOpen && (
        <AiWorkersCreate isModalOpen={isModalOpen} toggleModal={toggleModal} onSave={handleSaveWorker} />
      )}
    </div>
  );
};

export default AiWorkers;
