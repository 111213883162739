import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormInputSource from "../../../Ui/Input/FormInputSource";
import {
  addAccount,
  setOauthToken,
} from "../../../../redux/slices/gmailAccountsSlice";
import { jwtDecode } from "jwt-decode";
import SelectInput from "../../../Ui/Select/SelectInput";
import TagsInput from "../../../Ui/Input/TagsInput";
import googleIcon from "../../../../assets/images/google-icon.png";

const GmailInputLayout = ({ onFieldChange }) => {
  const dispatch = useDispatch();
  const existingAccounts = useSelector(
    (state) => state.gmailAccounts.existingAccounts
  );

  const [gmailConfig, setGmailConfig] = useState({
    connection: "",
    action: "",
    to: "",
    cc: "",
    bcc: "",
    subject: "",
    content: "",
    description: "",
    labels: [],
    tags: [],
    outputOption: "appendBody",
  });

  const handleFieldChange = useCallback(onFieldChange, [onFieldChange]);

  useEffect(() => {
    if (handleFieldChange) {
      handleFieldChange("Gmail", gmailConfig);
    }
  }, [gmailConfig, handleFieldChange]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGmailConfig((prevConfig) => ({ ...prevConfig, [name]: value }));
  };

  const handleAccountSelection = (e) => {
    const { value } = e.target;
    setGmailConfig((prevConfig) => ({ ...prevConfig, connection: value }));
  };

  const handleGoogleLogin = () => {
    window.google.accounts.id.initialize({
      client_id:
        "508080835431-s9ovoa2d5lkf4i3m3glj8tf3rppbosdm.apps.googleusercontent.com",
      callback: handleCredentialResponse,
    });
    window.google.accounts.id.prompt();
  };

  const handleCredentialResponse = (response) => {
    const userObject = jwtDecode(response.credential);
    dispatch(addAccount(userObject.email));
    dispatch(setOauthToken(response.credential));
  };

  const setTags = (newTags) => {
    setGmailConfig((prevConfig) => ({ ...prevConfig, tags: newTags }));
  };

  return (
    <div className=" flex flex-col p-5 bg-white inner-page-bg-input">
      <div className="mb-4 w-full w-2/3 ">
        <label className="block text-gray-700 text-sm font-normal px-1 pt-2.5 pb-1 uppercase tracking-wider">
          Connection
        </label>
        {existingAccounts.length > 0 ? (
          <SelectInput
            name="connection"
            className=" w-full px-3.5 h-10 font-semibold"
            value={gmailConfig.connection}
            options={existingAccounts.map((account) => ({
              value: account,
              label: account,
            }))}
            onChange={handleAccountSelection}
          />
        ) : (
          <button
            type="button"
            onClick={handleGoogleLogin}
            className="w-full px-3.5 h-10 border border-[#e1e1e1] text-black flex items-center
            rounded-md text-base  bg-[#fafbff] place-content-center"
          >
            <img
              src={googleIcon}
              alt="icon"
              className="w-5 h-5 object-cover p-0.5 mx-1"
            />
            <span className="px-1">Connect to a New Account</span>
          </button>
        )}
      </div>

      <div className="flex flex-col">
        <label className="block text-gray-700 text-sm font-normal px-1 pt-2.5 pb-1 uppercase tracking-wider">
          Action
        </label>
        <SelectInput
          name="action"
          value={gmailConfig.action}
          options={[
            { value: "createDraft", label: "Create Draft" },
            { value: "sendEmail", label: "Send Email" },
          ]}
          onChange={handleChange}
          className="w-full"
        />
      </div>

      {[
        { label: "To", name: "to", type: "email" },
        { label: "Cc", name: "cc", type: "text" },
        { label: "Bcc", name: "bcc", type: "text" },
        { label: "Subject", name: "subject", type: "text" },
        { label: "Content", name: "content", type: "textarea" },
      ].map((input, index) => (
        <div key={index} className="flex flex-col items-start ">
          <label className="block text-gray-700 text-sm font-normal px-1 relative top-3.5 pb-1 uppercase tracking-wider">
            {input.label}
          </label>
          <FormInputSource
            type={input.type}
            name={input.name}
            value={gmailConfig[input.name]}
            onChange={handleChange}
            required
            className="w-full p-0 m-0"
          />
        </div>
      ))}
      <div className="flex flex-col items-start mb-6">
        <label className="block text-gray-700 text-sm font-normal px-1 py-4 pb-1 uppercase tracking-wider">
          Output Option
        </label>
        <div className="flex flex-col mt-3 pl-4">
          <label className="flex items-center px-1 pb-2">
            <input
              type="radio"
              name="outputOption"
              value="appendBody"
              checked={gmailConfig.outputOption === "appendBody"}
              onChange={handleChange}
              className="mr-2 text-black px-1 tracking-wider w-5 h-5 cursor-pointer"/>
            <span className="ml-2 text-black text-base font-light	">
              Append to Email Body
            </span>
          </label>
          
          <label className="flex items-center px-1 py-1">
            <input
              type="radio"
              name="outputOption"
              value="uploadAttachment"
              checked={gmailConfig.outputOption === "uploadAttachment"}
              onChange={handleChange}
              className="mr-2 text-black px-1 tracking-wider  w-5 h-5 cursor-pointer"
            />
            <span className="ml-2 text-black text-base font-light	">
              Upload as Attachment
            </span>
          </label>
        </div>
      </div>

      <div className=" gap-4 mb-2">
        <label className="block text-gray-700 text-sm font-normal px-1 pt-2.5 pb-1 uppercase tracking-wider">
          Tags
        </label>
        <TagsInput
          tags={gmailConfig.tags}
          setTags={setTags}
          className="w-full"
        />
      </div>

      <div className="mb-6 mt-3">
        <FormInputSource
          type="text"
          label="Description"
          name="description"
          value={gmailConfig.description}
          onChange={handleChange}
          className="w-full"
        />
      </div>
    </div>
  );
};

export default GmailInputLayout;
