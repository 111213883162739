// AiWorkers.js
import React from "react";
import AiWorkflows from "../components/AiWorkflows/AiWorkflows";


const Aiworkflows = () => {

  return (
    <div>
     <AiWorkflows/>
    </div>
  );
};

export default Aiworkflows;
