import React, { useState } from "react";

const ItemsExtractorLayout = ({ onChange }) => {
  const [fileContent, setFileContent] = useState("");
  const [extractedItems, setExtractedItems] = useState([]);

  // Handle file upload and read its content
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const content = e.target.result;
      setFileContent(content);
      extractItemsFromContent(content);
    };

    if (file) {
      reader.readAsText(file);
    }
  };

  // Function to extract items from the file content
  const extractItemsFromContent = (content) => {
    // Example extraction logic: splitting by newline or comma
    const items = content.split(/\r?\n/).filter((item) => item.trim() !== "");
    setExtractedItems(items);
    onChange("itemsExtractor", items);
  };

  return (
    <div>
      <h4 className="text-xl font-semibold mb-4">Items Extractor</h4>
      <input
        type="file"
        accept=".txt"
        onChange={handleFileUpload}
        className="mb-4"
      />
      <textarea
        className="w-full h-40 p-2 border border-gray-300 rounded-lg"
        value={fileContent}
        readOnly
        placeholder="File content will appear here..."
      />
      {extractedItems.length > 0 && (
        <div className="mt-4">
          <h5 className="font-semibold">Extracted Items:</h5>
          <p>{extractedItems.map((item, idx) => `"${item}"`).join(", ")}</p>
        </div>
      )}
    </div>
  );
};

export default ItemsExtractorLayout;