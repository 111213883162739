import React from 'react';

const LabelSelector = ({ labels = [], selectedLabels = [], onLabelChange }) => {
  const handleAddLabel = (e) => {
    const label = e.target.value;
    if (label && !selectedLabels.includes(label)) {
      onLabelChange([...selectedLabels, label]);
    }
    e.target.value = ""; // Reset the dropdown after selection
  };

  const handleRemoveLabel = (labelToRemove) => {
    onLabelChange(selectedLabels.filter((label) => label !== labelToRemove));
  };

  return (
    <div className='w-full'>
      <div className='w-full'>
        <select onChange={handleAddLabel} className="
        w-full p-2.5 h-11 border border-slate-300 focus:outline-none focus:shadow-outline rounded-md text-sm font-normal shadow-sm
       ">
          <option value="">-- Select Label --</option>
          {labels.map((label) => ( 
            <option key={label} value={label}> 
              {label}
            </option>
          ))}
        </select>
      </div>
      <div className="flex flex-wrap gap-2 ">
        {selectedLabels.map((label) => (
          <div
            key={label}
            className="flex items-center space-x-2 my-4 border border-pink-200 bg-rose-50 text-pink-500 px-3 py-1 rounded-full cursor-pointer"
            onClick={() => handleRemoveLabel(label)}
          >
            <span>{label}</span>
            <span>&times;</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LabelSelector;
