const sourceOptions = [
    { value: "", label: "--Select Source--" },
    { value: "Gmail", label: "Gmail" },
    // { value: "GoogleDrive", label: "Google Drive" },
    { value: "GoogleDocs", label: "Google Docs" },
    { value: "GoogleSheets", label: "Google Sheets" },
    { value: "OneDrive", label: "OneDrive" },
    { value: "OneNote", label: "OneNote" },
    { value: "Outlook", label: "Outlook" },
    { value: "RSSFeed", label: "RSS Feed" },
    { value: "YouTube", label: "YouTube" },
    { value: "Apple", label: "Apple Podcasts" },
    { value: "Webpage", label: "Webpage" },
  ];
  
  export default sourceOptions;
  