import React, { useState, useCallback } from "react";
import Modal from "../Ui/Dialogs/dialogs";
import AiWorkerOptions from "../../assets/data/AiWorker";
import ManualInstructionLayout from "./aiworker/ManualInstructionLayout";
import TemplateAiWorkerLayout from "./aiworker/TemplateAiWorkerLayout";
import ItemsExtractorLayout from "./aiworker/ItemsExtractorLayout";
import EmailSummarizerLayout from "./aiworker/EmailSummarizerLayout";
import TableExtractorLayout from "./aiworker/TableExtractorLayout";

const AddAiWorker = ({ isModalOpen, toggleModal }) => {
  const [activeTab, setActiveTab] = useState("Input");
  const [selectedSource, setSelectedSource] = useState("");
  const [formData, setFormData] = useState({});

  const handleTabClick = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleSourceChange = (event) => {
    const selected = event.target.value;
    setSelectedSource(selected);
  };

  const handleFieldChange = useCallback((source, data) => {
    setFormData((prevData) => ({ ...prevData, [source]: data }));
  }, []);

  const handleSave = () => {
    console.log("Form Data Saved: ", formData);
    // Add logic to handle form data submission, e.g., API call
  };

  const renderLayout = () => {
    switch (selectedSource) {
      case "manual":
        return <ManualInstructionLayout onChange={handleFieldChange} />;
      case "template":
        return <TemplateAiWorkerLayout onChange={handleFieldChange} />;
      case "itemsextractor":
        return <ItemsExtractorLayout onChange={handleFieldChange} />;
      case "emailsummarizer":
        return <EmailSummarizerLayout onChange={handleFieldChange} />;
      case "tableextracter":
        return <TableExtractorLayout onChange={handleFieldChange} />;
      default:
        return null;
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      toggle={toggleModal}
      Maintitle="AIWorker"
      Subtitle="Add Workflows"
    >
      <div className="flex place-content-between pt-2 pb-4 bg-[#fdeeff] px-4 md:px-5 border-b border-gray-200">
        <div className="flex flex-col items-start pt-2 w-2/5">
          <h3 className="text-3xl font-bold text-lime-800 w-full text-start flex items-center">
            Add Ai Worker
          </h3>
          <select
            className="w-full p-2 rounded-lg h-12 mt-2.5 pr-10 border border-gray-400 bg-gray-50"
            onChange={handleSourceChange}
            value={selectedSource}
          >
            {AiWorkerOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="w-full mx-auto p-6">{renderLayout()}</div>

      {selectedSource === "manual" && (
        <button
          className="bg-blue-500 text-white py-2 px-4 rounded-lg mt-4"
          onClick={handleSave}
        >
          Save
        </button>
      )}
    </Modal>
  );
};

export default AddAiWorker;