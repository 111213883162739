import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addAccount,
  setOauthToken,
} from "../../../../redux/slices/gmailAccountsSlice";
import GooglePickerComponent from "../GooglePickerComponent";
import { jwtDecode } from "jwt-decode";
import SelectInput from "../../../Ui/Select/SelectInput";
import FormInputSource from "../../../Ui/Input/FormInputSource";
import googleIcon from "../../../../assets/images/google-icon.png";
import BasicDialog from "../../../Ui/Dialogs/basic-dailog";
import TagsInput from "../../../Ui/Input/TagsInput";
import googleDriveIcon from "../../../../assets/images/google-drive-icon.webp";


const GoogleSheetInputLayout = ({ onFieldChange }) => {
  const dispatch = useDispatch();
  const existingAccounts = useSelector(
    (state) => state.gmailAccounts.existingAccounts
  );
  const oauthToken = useSelector((state) => state.gmailAccounts.oauthToken);

  const [gmailConfig, setGmailConfig] = useState({
    connection: "",
    spreadsheetId: "",
    filename: "",
    outputOption: "append", // Default radio selection
    description: "",
    customizedText: "", // Add this field for the textarea input
    tags: [],
  });

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false); // Dialog state
  const [spreadsheetOptions, setSpreadsheetOptions] = useState([
    { value: "spreadsheet1", label: "Preloaded Spreadsheet 1" },
    { value: "spreadsheet2", label: "Preloaded Spreadsheet 2" },
  ]);

  const [filenameOptions] = useState([
    { value: "projecttracker", label: "Project Tracker" },
    { value: "anotherspreadsheet", label: "Another Spreadsheet" },
    { value: "monthlyexpenses", label: "Monthly Expenses" },
  ]);

  const handleFieldChange = useCallback(onFieldChange, [onFieldChange]);

  useEffect(() => {
    handleFieldChange("Gmail", gmailConfig);
  }, [gmailConfig, handleFieldChange]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGmailConfig((prevConfig) => ({ ...prevConfig, [name]: value }));
  };

  const handleAccountSelection = (e) => {
    setGmailConfig((prevConfig) => ({
      ...prevConfig,
      connection: e.target.value,
    }));
  };

  const handleGoogleLogin = () => {
    window.google.accounts.id.initialize({
      client_id:
        "508080835431-s9ovoa2d5lkf4i3m3glj8tf3rppbosdm.apps.googleusercontent.com",
      callback: handleCredentialResponse,
    });
    window.google.accounts.id.prompt();
  };

  const handleCredentialResponse = (response) => {
    const userObject = jwtDecode(response.credential);
    dispatch(addAccount(userObject.email));
    dispatch(setOauthToken(response.credential));
  };

  const handleFileSelected = (fileId) => {
    setSpreadsheetOptions([
      { value: "spreadsheet1", label: "Spreadsheet 1" },
      { value: "spreadsheet2", label: "Spreadsheet 2" },
    ]);
    setGmailConfig((prevConfig) => ({ ...prevConfig, spreadsheetId: fileId }));
  };

  const handleFileInputClick = () => {
    if (oauthToken) {
      setIsPickerOpen(true);
    }
  };

  const handleCustomEditorClick = () => {
    setIsDialogOpen(true); // Open the dialog when the custom editor icon is clicked
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false); // Close the dialog
  };

  const setTags = (newTags) => {
    setGmailConfig((prevConfig) => ({ ...prevConfig, tags: newTags }));
  };

  return (
    <div className="flex flex-col p-5 bg-white inner-page-bg-input">
      <div className="mb-4 w-full ">
        <label className="block text-gray-700 text-sm font-normal px-1 pt-2.5 pb-1 uppercase tracking-wider">
          Connection
        </label>
        {existingAccounts.length > 0 ? (
          <SelectInput
            name="connection"
            value={gmailConfig.connection}
            className="w-full px-3.5 h-10 font-semibold"
            options={existingAccounts.map((account) => ({
              value: account,
              label: account,
            }))}
            onChange={handleAccountSelection}
          />
        ) : (
          <button
            type="button"
            onClick={handleGoogleLogin}
            className="w-full px-3.5 h-10 border border-[#e1e1e1] text-black flex items-center rounded-md text-base bg-[#fafbff] place-content-center"
          >
            <img
              src={googleIcon}
              alt="icon"
              className="w-5 h-5 object-cover p-0.5 mx-1"
            />
            <span className="px-1">Connect to a New Account</span>
          </button>
        )}
      </div>

      {oauthToken && (
        <>
          <div className="flex flex-col mb-2 mt-6">
            <label className="text-gray-700 text-sm font-normal uppercase tracking-wider mb-1">
              File
            </label>
            <button
              type="button"
              onClick={handleFileInputClick}
              className="px-4 py-2.5 border bg-green-100 text-black rounded-md flex items-center place-content-center"
            >
              <img src={googleDriveIcon} alt="icon" className="w-4 h-4 mx-2" />
              Select a File from Google Drive
            </button>
          </div>

          {isPickerOpen && (
            <GooglePickerComponent
              oauthToken={oauthToken}
              onFileSelected={(fileId) => {
                handleFileSelected(fileId);
                setIsPickerOpen(false);
              }}
            />
          )}

          <div className="flex flex-col mb-2 mt-6">
            <label className="text-gray-700 text-sm font-normal uppercase tracking-wider mb-1">
              File Name
            </label>
            <div className="w-full">
              <SelectInput
                name="filename"
                value={gmailConfig.filename}
                options={filenameOptions}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="flex flex-col mb-9 mt-6">
            <label className="text-gray-700 text-sm font-normal uppercase tracking-wider mb-1">
              Spreadsheet
            </label>
            <div className="w-full">
              <SelectInput
                name="spreadsheetId"
                value={gmailConfig.spreadsheetId}
                options={spreadsheetOptions}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="flex flex-col mb-4">
            <label className="text-gray-700 text-sm font-normal uppercase tracking-wider">
              Output Options
            </label>
            <div className="flex gap-4 flex-col items-start place-content-start mx-2 my-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="outputOption"
                  value="append"
                  checked={gmailConfig.outputOption === "append"}
                  onChange={handleChange}
                  className="mr-2 text-black px-1 tracking-wider w-5 h-5 cursor-pointer"
                />
                <span className="ml-2 text-black text-base font-light	">
                  Append output to document
                </span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="outputOption"
                  value="replace"
                  checked={gmailConfig.outputOption === "replace"}
                  onChange={handleChange}
                  className="mr-2 text-black px-1 tracking-wider w-5 h-5 cursor-pointer"
                />
                <span className="ml-2 text-black text-base font-light	">
                  Replace document with output
                </span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="outputOption"
                  value="customize"
                  checked={gmailConfig.outputOption === "customize"}
                  onChange={handleChange}
                  className="mr-2 text-black px-1 tracking-wider w-5 h-5 cursor-pointer"
                />
                <span className="ml-2 text-black text-base font-light	">
                  Customize output
                </span>
                <img
                  src={googleIcon}
                  alt="Customize icon"
                  className="w-5 h-5 object-cover p-0.5 ml-2 cursor-pointer"
                  onClick={handleCustomEditorClick}
                />
              </label>
            </div>
          </div>

          {isDialogOpen && (
            <BasicDialog
              isOpen={isDialogOpen}
              onClose={handleCloseDialog}
              title="Customize Output"
            >
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-normal uppercase tracking-wider mb-2">
                  Output Details
                </label>
                <textarea
                  name="customizedText"
                  value={gmailConfig.customizedText}
                  onChange={handleChange}
                  className="w-full h-24 px-3 py-2 border border-gray-300 rounded-md"
                  placeholder="<client_name>                              Campaign Performance&#13;&#10;Report Date:&#13;&#10;<findings>"
                />
              </div>
            </BasicDialog>
          )}

          <div className=" gap-4 mb-2">
            <label className="block text-gray-700 text-sm font-normal px-1 pt-2.5 pb-1 uppercase tracking-wider">
              Tags created
            </label>
            <TagsInput
              tags={gmailConfig.tags}
              setTags={setTags}
              className="w-full"
            />
          </div>

          <div className="mt-6 mb-3">
            <FormInputSource
              label="Description"
              type="text"
              name="description"
              value={gmailConfig.description}
              onChange={handleChange}
              className="w-full "
            />
          </div>
        </>
      )}
    </div>
  );
};

export default GoogleSheetInputLayout;
