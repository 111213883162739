import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Handle your logout logic here
    navigate('/login');
  }, [navigate]);

  return (
    <div className="flex justify-center items-center h-screen bg-gray-200">
      <h2>Logging out...</h2>
    </div>
  );
};

export default Logout;
