import React, { useState } from "react";
import Modal from "../Ui/Dialogs/dialogs";
import FormInput from "../Common/FormInput/FormInput";

const AiWorkersCreate = ({ isModalOpen, toggleModal, onSave }) => {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    const handleSubmit = () => {
        const newWorker = {
            id: Date.now().toString(),
            name,
            instruction: description,
            status: "Active",
        };
        onSave(newWorker); 
    };

    return (
        <Modal isOpen={isModalOpen} toggle={toggleModal} title="My AI Worker">
            <p className="text-slate-400 text-center px-12 pt-1 pb-6 text-sm">
                You can view their statuses, create new workers, and perform various actions to maintain optimal performance.
            </p>
            <div className="p-4 md:px-12 md:py-1 flex flex-col gap-3">
                <FormInput label="AI Worker Name" type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                <FormInput label="Description" type="textarea" value={description} onChange={(e) => setDescription(e.target.value)} required />
              
                <div className="w-full flex place-content-end my-5">
                    <button className="bg-orange-400 hover:bg-orange-600 text-sm text-white px-7 pt-1 pb-1.5 rounded-md shadow flex items-center mx-2" onClick={handleSubmit}>
                        Save
                    </button>
                    <button
                        className="bg-violet-50 hover:bg-violet-100 text-sm px-7 pt-1 pb-1.5 rounded-md shadow-sm
                        border flex items-center mx-2"
                        onClick={toggleModal}
                    >  
                        Cancel 
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default AiWorkersCreate;
