import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addWorker, getAiWorkers } from '../../redux/slices/aiWorkersSlice';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';

const AiWorkflowHisory = () => {
  const dispatch = useDispatch();
  const { workers, status, error } = useSelector((state) => state.aiWorkers);
  const [checkedItems, setCheckedItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(getAiWorkers());
    }  
  }, [status, dispatch]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setCheckedItems(workers.map(worker => worker.id));
    } else {
      setCheckedItems([]);
    }
  };

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setCheckedItems([...checkedItems, id]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== id));
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSaveWorker = (newWorker) => {
    dispatch(addWorker(newWorker));
    toggleModal();
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-1 lg:w-full w-[80%]">
        <Navbar />
        <div className="p-3 md:p-6">
          {status === 'loading' && <p>Loading...</p>}
          {status === 'failed' && <p>{error}</p>}
          {status === 'succeeded' && ( 
            <div className="relative overflow-x-auto"> 
              <div className="flex md:flex-row flex-col place-content-between mb-4">
               
                <div className="flex items-start">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5 m-1">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
                  </svg>
                  <div>
                    <h1 className="text-black font-bold text-xl text-start flex items-center">Ai WorkFlow History</h1>
                    <p className="text-black text-sm">Manage your AI workerFlow History. You can view their TimeStamp and  Workflow , Sequence Name .</p>
                  </div>
                 </div>
              </div>

              <div className="w-full shadow p-5 bg-white rounded-lg">
                <table className="w-full text-sm text-left text-black">
                  <thead className="text-xs text-black uppercase bg-slate-100">
                    <tr>
                      <th scope="col" className="px-1 py-2 md:px-6 md:py-3">
                        <input
                          className="w-4 h-4"
                          type="checkbox"  
                          checked={checkedItems.length === workers.length}
                          onChange={handleSelectAll}
                        />
                      </th>
                      <th className="px-1 py-2 md:px-6 md:py-3 font-normal w-[5%]">#</th>
                      <th className="px-1 py-2 md:px-6 md:py-3 font-normal">TimeStamp</th>
                      <th className="px-1 py-2 md:px-6 md:py-3 font-normal">Workflow Name</th>
                      <th className="px-1 py-2 md:px-6 md:py-3 font-normal">Sequence Name</th>
                      <th className="px-1 py-2 md:px-6 md:py-3 font-normal">Destination</th>
                    </tr>
                  </thead>  

                  <tbody> 
                    {workers.map((worker, index) => (
                      <tr key={worker.id} className="bg-white border-b hover:bg-slate-200">
                        <td className="px-1 py-2 md:px-6 md:py-3">
                          <input 
                            className="w-4 h-4"
                            type="checkbox"
                            checked={checkedItems.includes(worker.id)}
                            onChange={(event) => handleCheckboxChange(event, worker.id)}
                          />
                        </td>
                        {/* <td className="px-1 py-2 md:px-6 md:py-3">{index + 1}</td>
                        <td className="px-1 py-2 md:px-6 md:py-3">{history.id}</td>
                        <td className="px-1 py-2 md:px-6 md:py-3">{history.timestamp}</td>
                        <td className="px-1 py-2 md:px-6 md:py-3">{history.workflow}</td>
                        <td className="px-1 py-2 md:px-6 md:py-3">{history.sequence}</td>
                        <td className="px-1 py-2 md:px-6 md:py-3">{history.destination}</td> */}
                      </tr>
                    ))}
                  </tbody> 
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
   
    </div>
  );
};

export default AiWorkflowHisory;
