import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchUsers } from '../../services/api/apiService';

// Fetch users using createAsyncThunk
export const getUsers = createAsyncThunk('users/getUsers', async () => {
  const response = await fetchUsers();
  return response;
});

// Add deleteUser action
export const deleteUser = createAsyncThunk('users/deleteUser', async (userId) => {
  // Call API to delete user (replace with your API call)
  await fetch(`/api/users/${userId}`, { method: 'DELETE' });
  return userId;
});

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    users: [], // Initial users list
    status: 'idle', // Track loading status
    error: null, // Track any error
  },
  reducers: {
    addUser: (state, action) => {
      const newUser = {
        id: state.users.length + 1, // Generate a new ID
        name: action.payload.name,
        email: action.payload.email,
        plan: action.payload.plan || 'Free', // Default to 'Free' if not provided
        sprintsUsed: action.payload.sprintsUsed || '0/50', // Default to '0/50' if not provided
      };
      state.users.push(newUser); // Add the new user
    },
    updateUser: (state, action) => {
      const { id, name, email, plan, sprintsUsed } = action.payload;
      const existingUser = state.users.find(user => user.id === id);
      if (existingUser) {
        existingUser.name = name;
        existingUser.email = email;
        existingUser.plan = plan;
        existingUser.sprintsUsed = sprintsUsed;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.status = 'loading'; // Set loading state
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.status = 'succeeded'; // Set succeeded state
        state.users = action.payload; // Update users list with fetched data
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.status = 'failed'; // Set failed state
        state.error = action.error.message; // Capture error message
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.users = state.users.filter(user => user.id !== action.payload);
      });
  },
});

export const { addUser, updateUser } = usersSlice.actions;
export default usersSlice.reducer;