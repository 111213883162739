import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addWorker, getAiWorkers } from "../../redux/slices/aiWorkersSlice";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";

const AiSettings = () => {
  const { status, error } = useSelector((state) => state.aiWorkers);

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-1 lg:w-full w-[80%]">
        <Navbar />
        <div className="p-3 md:p-6">
          <div className="relative overflow-x-auto">
            <div className="flex md:flex-row flex-col place-content-between mb-4">
              <div className="flex items-start">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" 
                className="size-5 m-1 mt-1"><path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12a7.5 7.5 0 0 0 15 0m-15 0a7.5 7.5 0 1 1 15 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077 1.41-.513m14.095-5.13 1.41-.513M5.106 17.785l1.15-.964m11.49-9.642 1.149-.964M7.501 19.795l.75-1.3m7.5-12.99.75-1.3m-6.063 16.658.26-1.477m2.605-14.772.26-1.477m0 17.726-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205 12 12m6.894 5.785-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495"></path></svg>
                <div>
                  <h1 className="text-black font-bold text-xl text-start flex items-center">
                    Ai Subscription
                  </h1>
                  <p className="text-black text-sm mb-3">
                    Manage your AI Settings . You can view their view plan .
                  </p>
                </div>
              </div>
            </div>

            <div className="flex gap-4 md:w-3/4	w-full md:flex-row flex-col">
              <div className="border-8 border-white bg-gray-50 md:w-2/4	w-full p-4 rounded">
                <div className="flex place-content-between pt-1 px-1">
                  <h1 className="text-xl font-bold">Free Plan</h1>
                  <h2 className="text-2xl">
                    $0 <span className="text-base">/ Month</span>
                  </h2>
                </div>
                <ul className="my-3 px-2">
                  <li className="py-3 text-gray-500">50 Sprints /mo</li>
                  <li className="py-2  text-gray-500">2 Workflows</li>
                </ul>
              </div>
              <div className="border-8 border-white bg-gray-50 md:w-2/4	w-full p-4 rounded">
                <div className="flex place-content-between ">
                  <h1 className="text-xl font-bold">Professional</h1>
                  <h2 className="text-2xl">
                    $30 <span className="text-base">/Month</span>
                  </h2>
                </div>
                <ul className="my-3 px-2">
                  <li className="py-3 text-gray-500">750 Sprints /mo</li>
                  <li className="py-2  text-gray-500">Unlimited Workflows</li>
                </ul>
              </div>
            </div>

            <div className="flex gap-1 md:w-3/4	w-full flex-col border-8 border-white bg-gray-50 shadow my-4 p-7 rounded">
              <h1 className="text-xl font-bold">Delete My Account</h1>
              <p className="p-0 m-0 text-gray-500 my-2">
                <span className="text-red-200">Warning:</span> Account deletion is permanent and cannot be reversed.
                All data, including project data and subscriptions, will be
                immediately and permanently deleted.
              </p>
              <p className="p-0 m-0 text-gray-500 my-2">Please type DELETE to confirm deletion of this account.</p>
              <button type="button" className="mt-5 text-white bg-red-200 font-normal 
              rounded text-sm px-7 py-2 me-2 mb-2 w-fit tracking-wider">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiSettings;
