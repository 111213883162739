import React from "react";

const FormInputSource = ({ name, label, type, required, value, onChange, className }) => (
  <div className={`mb-2 ${className}`}>
    <label className="block text-gray-700 text-sm font-normal	px-1 pt-2.5 pb-1 uppercase tracking-wider	">
      {label} {required }
    </label>
    {type === "textarea" ? (
      <textarea
        value={value}
        name={name} // Add this line
        onChange={onChange}
        required={required}
        className="appearance-none border rounded w-full py-2 px-3 text-gray-700 
          leading-tight border-inherit text-sm
          focus:outline-none focus:shadow-outline shadow-sm"
      />
    ) : (
      <input
        type={type}
        name={name} // Add this line
        value={value}
        onChange={onChange}
        required={required} 
        className="w-full p-2.5 h-11 border border-slate-300 
         focus:outline-none focus:shadow-outline rounded-md text-sm font-normal shadow-sm"
      />
    )}
  </div>
);

export default FormInputSource;
