import React, { useState } from "react";
import FormInputSource from "../../../Ui/Input/FormInputSource";

const YoutubeWidgetLayout = () => {
  const [emailSplitter, setEmailSplitter] = useState(false);
  const [description, setDescription] = useState("Emails from Project Y");

  const handleCheckboxChange = () => {
    setEmailSplitter(!emailSplitter);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  return (
    <div className="flex flex-col gap-4 p-5 bg-white inner-page-bg-input">
      <div className="my-3 px-1">
        <label className="flex  items-center gap-1 relative -top-0.5">
          <input
            type="checkbox"
            checked={emailSplitter}
            onChange={handleCheckboxChange}
            className="form-checkbox h-4 w-4 text-orange-500  relative -top-0.5 cursor-pointer"
          />
          <span className="ml-1 text-gray-700 text-lg">
            <b className="text-black text-base font-semibold px-1 uppercase tracking-wider">
            Video Splitter: </b>  <span className="font-light">
             AI to process each video individually as input </span>
          </span>
        </label>
      </div>

      <div className="mb-6 mt-5">
        <FormInputSource
          label="Description"
          type="text"
          id="description"
          value={description}
          onChange={handleDescriptionChange}
          className="w-full"
        />
      </div>
    </div>
  );
};

export default YoutubeWidgetLayout;
