import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Common/Logo/Logo";
import Button from "../Common/Button/Button";
import FormInput from "../Common/FormInput/FormInput";
import LeftPanel from "../Inner/LeftPanel";

const ForgotPassword = () => {
  return (
    <div className="flex justify-center md:items-center bg-white relative min-h-screen bg-img px-2">
      <div className="w-full max-w-7xl mx-auto md:flex">
        <div className="md:flex w-full items-start place-content-center py-10 lg:gap-20">
          <LeftPanel />
          <div className="md:w-[45%] w-full flex flex-col items-center relative bg-white p-4 md:px-12 md:py-10 rounded-lg">
            <div className="md:hidden block md:my-0 mt-6 mb-9">
              <Logo isMobile />
            </div>
            <h1 className="text-2xl md:text-3xl text-start w-full font-semibold">
              Forgot Password
            </h1>
            <p className="text-start text-sm mb-8 py-1 block w-full text-gray-400">
              For recover your password
            </p>

            <form className="w-full mb:mt-9">
              <FormInput label="Email" type="email" required />
              <Link to="/login">
                <Button className="mt-7 bg-blue-500 text-white p-2 h-10 w-full">
                  Forgot
                </Button>
              </Link>
            </form>
            <p className="text-center text-sm mt-12 py-3 block w-full text-gray-400">
              Already have an account?{" "}
              <Link to="/login" className="text-blue-400">
                Sign in
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
