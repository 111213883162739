import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../Ui/Dialogs/dialogs";
import SourceBox from "../Ui/Box/SourceBox";
import Button from "../Common/Button/Button";
import AddSource from "../Source/AddSource";
import AddDestination from "../Destination/AddDestination";
import AddAiWorker from "../AddAiWorker/addAiWorker";
import ScheduleDialog from "../Ui/Dialogs/ScheduleDialog";
import { addAiWorkflow, getAiWorkflows, deleteAiWorkflow } from "../../redux/slices/aiWorkflowsSlice";

const AiWorkFlowCreate = ({ isModalOpen, toggleModal }) => {
  const dispatch = useDispatch();
  const [workflowName, setWorkflowName] = useState("Workflow name"); // Editable title
  const [isEditingName, setIsEditingName] = useState(false); // Edit mode state
  const [isAddAiWorkerModalOpen, setIsAddAiWorkerModalOpen] = useState(false);
  const [isDestinationModalOpen, setIsDestinationModalOpen] = useState(false);
  const [isSourceModalOpen, setIsSourceModalOpen] = useState(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState("");

  const { workflows, status } = useSelector((state) => state.aiWorkflows); // Get workflows from Redux store

  useEffect(() => {
    dispatch(getAiWorkflows()); // Fetch workflows on component mount
  }, [dispatch]);

  const toggleAlertModal = () => {
    setIsSourceModalOpen(!isSourceModalOpen);
  };

  const toggleAddAiWorkerModal = () => {
    setIsAddAiWorkerModalOpen(!isAddAiWorkerModalOpen);
  };

  const toggleDestinationModal = () => {
    setIsDestinationModalOpen(!isDestinationModalOpen);
  };

  const toggleScheduleModal = () => {
    setIsScheduleModalOpen(!isScheduleModalOpen);
  };

  const handleScheduleChange = (e) => {
    setSelectedSchedule(e.target.value);
  };

  const handleSaveWorkflow = () => {
    const workflowData = {
      id: null,
      name: workflowName,
      isScheduled: true,
      scheduleTime: selectedSchedule,
      lastRun: "Never",
      run: false,
    };

    dispatch(addAiWorkflow(workflowData));
    toggleModal();
  };

  const toggleEditingName = () => {
    setIsEditingName(!isEditingName);
  };

  const handleDeleteWorkflow = (id) => {
    dispatch(deleteAiWorkflow(id));
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        toggle={toggleModal}
        Maintitle="AIWorker"
        Subtitle="Add Workflows"
      >
        <div className="flex place-content-between pt-2 pb-0.5 px-4 md:px-5 border-b border-gray-200 bg-slate-100">
          <div className="flex flex-col items-start pt-2">
            <div className="flex items-center">
              {isEditingName ? (
                <input
                  type="text"
                  className="text-3xl font-bold text-black w-full text-start flex items-center border-none bg-transparent focus:outline-none"
                  value={workflowName}
                  onChange={(e) => setWorkflowName(e.target.value)}
                />
              ) : (
                <h1 className="text-3xl font-bold text-black w-full">{workflowName}</h1>
              )}
              <button
                onClick={toggleEditingName}
                className="ml-2 text-gray-600 hover:text-black"
              >
                {isEditingName ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15.232 5.232a3 3 0 114.242 4.242L9 20l-4 1 1-4 10.232-10.232z"
                    />
                  </svg>
                )}
              </button>
            </div>
            <p className="text-slate-500 text-center pb-3 text-base">
              You can arrange various actions to maintain optimal performance.
            </p>
          </div>
        </div>

        <div className="p-4 mk:px-6 md:py-4 flex flex-col gap-3 bg-[#f9f9ff]">
          <div className="flex items-end place-content-end w-full mt-1">
            <button
              onClick={toggleScheduleModal}
              className="text-white px-4 py-2 ml-2 antialiased rounded bg-[#60befa] hover:bg-cyan-400 shadow flex items-center place-content-between"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                />
              </svg>
              <span className="pl-2 font-medium text-base tracking-wider">
                Set Schedule
              </span>
            </button>
          </div>

          {/* Other workflow configuration */}
          <div className="flex w-full pt-5">
            {/* Source section */}
            <div className="flex flex-col w-2/6 p-4 add-source-line">
              <div className="flex items-center">
                <h2 className="text-lg font-semibold px-1.5 pb-0.5 text-black">
                  Source
                </h2>
                <div className="relative group">
                  <button
                    onClick={toggleAlertModal}
                    className="flex place-content-center text-white bg-[#ff518d] font-medium rounded-full text-sm w-6 h-6 text-center me-2"
                  >
                    +
                  </button>
                  <div className="absolute bottom-full mb-2 hidden group-hover:block w-max py-1 px-2 text-sm text-white bg-[#ff518d] rounded-md">
                    Add Source
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col px-1 pt-3 pb-8">
                {workflows.length > 0 ? (
                  workflows.map((workflow) => (
                    <SourceBox
                      key={workflow.id}
                      heading={workflow.name}
                      description="Source description"
                      onDelete={() => handleDeleteWorkflow(workflow.id)}
                    />
                  ))
                ) : (
                  <p>No sources added yet</p>
                )}
              </div>
            </div>

            {/* AI Worker section */}
            <div className="flex flex-col w-2/6 p-4 add-ai-worker-line">
              <div className="flex items-center">
                <h2 className="text-base font-semibold px-1.5 pb-0.5 text-black">
                  AI Worker
                </h2>
                <div className="relative group">
                  <button
                    onClick={toggleAddAiWorkerModal}
                    className="flex place-content-center text-white bg-fuchsia-600 font-medium rounded-full text-sm w-6 h-6 text-center me-2"
                  >
                    +
                  </button>
                  <div className="absolute bottom-full mb-2 hidden group-hover:block w-max py-1 px-2 text-sm text-white bg-fuchsia-600 rounded-md">
                    Add AI Worker
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col pt-3 pb-8">
                {workflows.length > 0 ? (
                  workflows.map((worker) => (
                    <SourceBox
                      key={worker.id}
                      heading={worker.name}
                      description="AI Worker description"
                      onDelete={() => handleDeleteWorkflow(worker.id)}
                    />
                  ))
                ) : (
                  <p>No AI workers added yet</p>
                )}
              </div>
            </div>

            {/* Destination section */}
            <div className="flex flex-col w-2/6 p-4 add-destination-line">
              <div className="flex items-center">
                <h2 className="text-base font-semibold px-1.5 pb-0.5 text-black">
                  Destinations
                </h2>
                <div className="relative group">
                  <button
                    onClick={toggleDestinationModal}
                    className="flex place-content-center text-white bg-[#ffb149] font-medium rounded-full text-sm w-6 h-6 text-center me-2"
                  >
                    +
                  </button>
                  <div className="absolute bottom-full mb-2 hidden group-hover:block w-max py-1 px-2 text-sm text-white bg-[#ffb149] rounded-md">
                    Add Destinations
                  </div>
                </div>
              </div>
              <div className="flex w-full flex-col px-1 pt-3 pb-8">
                {workflows.length > 0 ? (
                  workflows.map((destination) => (
                    <SourceBox
                      key={destination.id}
                      heading={destination.name}
                      description="Destination description"
                      onDelete={() => handleDeleteWorkflow(destination.id)}
                    />
                  ))
                ) : (
                  <p>No destinations added yet</p>
                )}
              </div>
            </div>
          </div>

          {/* Save and Cancel buttons */}
          <div className="w-full flex justify-center my-11">
            <Button
              onClick={handleSaveWorkflow}
              className="bg-green-500 hover:bg-green-400 text-white mx-1"
            >
              Save
            </Button>
            <Button
              onClick={toggleModal}
              className="bg-gray-200 border border-gray-200 hover:bg-gray-300 mx-1"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      <AddSource
        isModalOpen={isSourceModalOpen}
        toggleModal={toggleAlertModal}
      />

      <AddAiWorker
        isModalOpen={isAddAiWorkerModalOpen}
        toggleModal={toggleAddAiWorkerModal}
      />

      <AddDestination
        isModalOpen={isDestinationModalOpen}
        toggleModal={toggleDestinationModal}
      />

      <ScheduleDialog
        isOpen={isScheduleModalOpen}
        onClose={toggleScheduleModal}
        selectedSchedule={selectedSchedule}
        handleScheduleChange={handleScheduleChange}
      />
    </div>
  );
};

export default AiWorkFlowCreate;
