import React, { useState } from "react";

const CreateUser = ({ isModalOpen, toggleModal, onSave }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [plan, setPlan] = useState("Free");
  const [sprintsUsed, setSprintsUsed] = useState("0/50");

  const handleSubmit = (event) => {
    event.preventDefault();
    onSave({ name, email, plan, sprintsUsed });
  };

  return (
    <div
      className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity ${
        isModalOpen ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div className="flex items-center justify-center min-h-screen p-8">
        <div className="bg-white rounded-lg shadow-lg w-[40%]">
          <div className="flex place-content-between	bg-[#f1f4f7] rounded-t-lg ">
            <h2 className="text-lg font-bold   bg-[#f1f4f7] py-4 px-12 rounded-t-lg text-center w-full">
              Create New User
            </h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              onClick={toggleModal}
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6 text-gray-500 cursor-pointer m-1 "
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </div>
          <form onSubmit={handleSubmit} className=" px-8">
            <div className="mb-3">
              <label className="block text-base font-normal text-gray-600 p-0.5 mt-5">
                Name
              </label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="appearance-none block w-full px-2 py-1.5 bg-gray-50 border border-gray-300
                 rounded-lg text-gray-800 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 transition duration-300 ease-in-out"
                required
              />
            </div>
            <div className="mb-2.5">
              <label className="block text-base font-normal text-gray-600 p-0.5">
                Email
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="appearance-none block w-full px-2 py-1.5 bg-gray-50 border border-gray-300 rounded-lg text-gray-800 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 transition duration-300 ease-in-out"
                required
              />
            </div>
            <div className="mb-3">
              <label className="block text-base font-normal text-gray-600 p-0.5">
                Plan
              </label>
              <select
                value={plan}
                onChange={(e) => setPlan(e.target.value)}
                className="appearance-none block w-full px-2 py-1.5 bg-gray-50 border border-gray-300 rounded-lg text-gray-800 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 transition duration-300 ease-in-out"
              >
                <option value="Free">Free</option>
                <option value="Basic">Basic</option>
                <option value="Premium">Premium</option>
              </select>
            </div>
            <div className="mb-2">
              <label className="block text-base font-normal text-gray-600 p-0.5 pb-1.5">
                Sprints Used
              </label>
              <input
                type="text"
                value={sprintsUsed}
                onChange={(e) => setSprintsUsed(e.target.value)}
                className="appearance-none block w-full px-2 py-1.5 bg-gray-50 border border-gray-300 rounded-lg text-gray-800 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600 transition duration-300 ease-in-out"
                required
              />
            </div>
            <div className="flex justify-end my-10">
              <button
                type="button"
                onClick={toggleModal}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg mr-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-green-500 text-white px-6 py-2 rounded-md"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
