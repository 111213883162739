import React, { useState, useEffect } from "react";

const EmailSummarizerLayout = ({ onChange }) => {
  const [summary, setSummary] = useState([]);

  // Function to generate a dummy summary
  const summarizeEmails = () => {
    const dummyEmails = [
      {
        clientName: "Client A",
        findings: "Found issues with the data migration process.",
        closingDate: "2024-09-30",
      },
      {
        clientName: "Client B",
        findings: "No significant issues found, all systems are operational.",
        closingDate: "2024-10-05",
      },
      {
        clientName: "Client C",
        findings: "Discovered a discrepancy in the billing system.",
        closingDate: "2024-09-28",
      },
    ];

    setSummary(dummyEmails);
    onChange("emailSummarizer", dummyEmails); // Pass summarized data to parent
  };

  // UseEffect to run the summary generation on component mount
  useEffect(() => {
    summarizeEmails();
  }, []);

  return (
    <div>
      <h4 className="text-xl font-semibold mb-4">Email Summarizer</h4>
      {summary.length > 0 && (
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto border-collapse">
            <thead>
              <tr className="bg-gray-200 text-left">
                <th className="px-4 py-2">Client Name</th>
                <th className="px-4 py-2">Findings</th>
                <th className="px-4 py-2">Closing Date</th>
              </tr>
            </thead>
            <tbody>
              {summary.map((email, index) => (
                <tr key={index} className="border-t">
                  <td className="px-4 py-2">{email.clientName}</td>
                  <td className="px-4 py-2">{email.findings}</td>
                  <td className="px-4 py-2">{email.closingDate}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default EmailSummarizerLayout;