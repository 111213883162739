import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAiWorkflows, deleteAiWorkflowApi, saveAiWorkflowApi } from '../../services/api/apiService';

// Fetch AI workflows using createAsyncThunk
export const getAiWorkflows = createAsyncThunk(
  'aiWorkflows/getAiWorkflows',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchAiWorkflows();
      return response;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Save or update AI workflow
export const saveAiWorkflow = createAsyncThunk(
  'aiWorkflows/saveAiWorkflow',
  async (workflowData, { rejectWithValue }) => {
    try {
      const response = await saveAiWorkflowApi(workflowData); // Call the save API for workflow creation/update
      return response; // Return the saved/updated workflow
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Delete AI workflow action
export const deleteAiWorkflow = createAsyncThunk(
  'aiWorkflows/deleteAiWorkflow',
  async (workflowId, { rejectWithValue }) => {
    try {
      await deleteAiWorkflowApi(workflowId); // API call for deleting a workflow
      return workflowId; // Return deleted workflow ID
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// AI workflows slice
const aiWorkflowsSlice = createSlice({
  name: 'aiWorkflows',
  initialState: {
    workflows: [], // AI workflows list
    status: 'idle', // Track loading status for fetching
    error: null, // Capture any fetch error
    deleteStatus: 'idle', // Track delete status
    deleteError: null, // Capture delete error
    saveStatus: 'idle', // Track save status
    saveError: null, // Capture save error
  },
  reducers: {
    addAiWorkflow: (state, action) => {
      const newWorkflow = {
        id: action.payload.id, // Use the provided ID (e.g., from the backend or UI)
        name: action.payload.name,
        isScheduled: action.payload.isScheduled || false,
        scheduleTime: action.payload.scheduleTime || null,
        lastRun: action.payload.lastRun || 'Never', // Default to 'Never' if not provided
        run: action.payload.run || false, // Default to false if not provided
      };
      state.workflows.push(newWorkflow); // Add the new workflow
    },
    updateAiWorkflow: (state, action) => {
      const { id, name, isScheduled, scheduleTime, lastRun, run } = action.payload;
      const existingWorkflow = state.workflows.find((workflow) => workflow.id === id);
      if (existingWorkflow) {
        existingWorkflow.name = name || existingWorkflow.name;
        existingWorkflow.isScheduled = isScheduled !== undefined ? isScheduled : existingWorkflow.isScheduled;
        existingWorkflow.scheduleTime = scheduleTime || existingWorkflow.scheduleTime;
        existingWorkflow.lastRun = lastRun || existingWorkflow.lastRun;
        existingWorkflow.run = run !== undefined ? run : existingWorkflow.run;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch workflows
      .addCase(getAiWorkflows.pending, (state) => {
        state.status = 'loading'; // Set loading state
      })
      .addCase(getAiWorkflows.fulfilled, (state, action) => {
        state.status = 'succeeded'; // Set success state
        state.workflows = action.payload; // Replace workflows with fetched data
      })
      .addCase(getAiWorkflows.rejected, (state, action) => {
        state.status = 'failed'; // Set failed state
        state.error = action.payload; // Capture the error
      })

      // Save or update workflow
      .addCase(saveAiWorkflow.pending, (state) => {
        state.saveStatus = 'loading'; // Set save loading state
      })
      .addCase(saveAiWorkflow.fulfilled, (state, action) => {
        state.saveStatus = 'succeeded'; // Set save success state
        const existingWorkflow = state.workflows.find((wf) => wf.id === action.payload.id);
        if (existingWorkflow) {
          // Update the existing workflow if found
          Object.assign(existingWorkflow, action.payload);
        } else {
          // Otherwise, add a new workflow
          state.workflows.push(action.payload);
        }
      })
      .addCase(saveAiWorkflow.rejected, (state, action) => {
        state.saveStatus = 'failed'; // Set save failed state
        state.saveError = action.payload; // Capture save error
      })

      // Delete workflow
      .addCase(deleteAiWorkflow.pending, (state) => {
        state.deleteStatus = 'loading'; // Set delete loading state
      })
      .addCase(deleteAiWorkflow.fulfilled, (state, action) => {
        state.deleteStatus = 'succeeded'; // Set delete success state
        state.workflows = state.workflows.filter((workflow) => workflow.id !== action.payload); // Remove workflow from state
      })
      .addCase(deleteAiWorkflow.rejected, (state, action) => {
        state.deleteStatus = 'failed'; // Set delete failed state
        state.deleteError = action.payload; // Capture delete error
      });
  },
});

// Export actions and reducer
export const { addAiWorkflow, updateAiWorkflow } = aiWorkflowsSlice.actions;
export default aiWorkflowsSlice.reducer;
