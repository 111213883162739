import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addAccount,
  setOauthToken,
} from "../../../../redux/slices/gmailAccountsSlice";
import GooglePickerComponent from "../GooglePickerComponent";
import { jwtDecode } from "jwt-decode";
import SelectInput from "../../../Ui/Select/SelectInput";
import FormInputSource from "../../../Ui/Input/FormInputSource";
import googleIcon from "../../../../assets/images/google-icon.png";
import FolderSelectInput from "../../../Ui/Select/FolderSelectInput";
import TagsInput from "../../../Ui/Input/TagsInput";
import googleDriveIcon from "../../../../assets/images/google-drive-icon.webp";

const GoogleDocInputLayout = ({ onFieldChange }) => {
  const dispatch = useDispatch();
  const existingAccounts = useSelector(
    (state) => state.gmailAccounts.existingAccounts
  );
  const oauthToken = useSelector((state) => state.gmailAccounts.oauthToken);

  const [gmailConfig, setGmailConfig] = useState({
    connection: "",
    description: "",
    filename: "",
    spreadsheet: "",
    outputOption: "append", // Default radio selection
    customizeOutput: false, // Checkbox state
    customizedText: "", // Text area value
    tags: [],
  });

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState("");

  const handleFieldChange = useCallback(onFieldChange, [onFieldChange]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setGmailConfig((prevConfig) => ({
        ...prevConfig,
        [name]: checked,
      }));
    } else {
      setGmailConfig((prevConfig) => ({ ...prevConfig, [name]: value }));
    }
  };

  const handleChangeSelect = (e) => {
    const { name, value } = e.target;
    setGmailConfig((prevConfig) => ({ ...prevConfig, [name]: value }));
  };

  const handleFolderChange = (e) => {
    setSelectedFolder(e.target.value);
  };

  useEffect(() => {
    if (handleFieldChange) {
      handleFieldChange("Gmail", gmailConfig);
    }
  }, [gmailConfig, handleFieldChange]);

  const handleAccountSelection = (e) => {
    const { value } = e.target;
    setGmailConfig((prevConfig) => ({ ...prevConfig, connection: value }));
  };

  const handleGoogleLogin = () => {
    window.google.accounts.id.initialize({
      client_id:
        "508080835431-s9ovoa2d5lkf4i3m3glj8tf3rppbosdm.apps.googleusercontent.com",
      callback: handleCredentialResponse,
    });
    window.google.accounts.id.prompt(); // Display the One Tap prompt or the pop-up dialog
  };

  const handleCredentialResponse = (response) => {
    const userObject = jwtDecode(response.credential);
    dispatch(addAccount(userObject.email)); // Add the account to Redux
    dispatch(setOauthToken(response.credential)); // Store the token in Redux
  };

  const handleFileSelected = (fileId) => {
    console.log("Selected file ID:", fileId);
    // Handle the selected file ID as needed
  };

  const handleFileInputClick = () => {
    if (oauthToken) {
      setIsPickerOpen(true); // Set the picker to open
    }
  };

  const setTags = (newTags) => {
    setGmailConfig((prevConfig) => ({ ...prevConfig, tags: newTags }));
  };

  return (
    <div className=" flex flex-col p-5 bg-white inner-page-bg-input">
      <div className="mb-4 w-full w-2/3 ">
        <label className="block text-gray-700 text-sm font-normal px-1 pt-2.5 pb-1 uppercase tracking-wider">
          Connection
        </label>
        {existingAccounts && existingAccounts.length > 0 ? (
          <SelectInput
            name="connection"
            value={gmailConfig.connection}
            className=" w-full px-3.5 h-10 font-semibold"
            options={existingAccounts.map((account) => ({
              value: account,
              label: account,
            }))}
            onChange={handleAccountSelection}
          />
        ) : (
          <button
            type="button"
            onClick={handleGoogleLogin}
            className="w-full px-3.5 h-10 border border-[#e1e1e1] text-black flex items-center
            rounded-md text-base bg-[#fafbff] place-content-center"
          >
            <img
              src={googleIcon}
              alt="icon"
              className="w-5 h-5 object-cover p-0.5 mx-1"
            />
            <span className="px-1">Connect to a New Account</span>
          </button>
        )}
      </div>

      {oauthToken && (
        <>
          <div className="flex flex-col mt-2 mb-2">
          <label className="text-gray-700 text-sm font-normal uppercase tracking-wider mb-1">
              File
            </label>
            <button
              type="button"
              onClick={handleFileInputClick}
              className="px-4 py-2.5 border bg-green-100 text-black rounded-md flex items-center place-content-center"
            >
              <img src={googleDriveIcon} alt="icon" className="w-4 h-4 mx-2" />
              Select a File from Google Drive
            </button>
          </div>
          {isPickerOpen && (
            <GooglePickerComponent
              oauthToken={oauthToken}
              onFileSelected={(fileId) => {
                handleFileSelected(fileId);
                setIsPickerOpen(false); // Close the picker after selection
              }}
            />
          )}
          <div className="mb-6 mt-3">
            <FormInputSource
              label="Description"
              type="text"
              name="description"
              value={gmailConfig.description}
              onChange={handleChange}
              className="w-full"
            />
          </div>
        </>
      )}

      <div className="mb-2 w-full  gap-4 items-center ">
        <label className="block text-gray-700 text-sm font-normal	px-1 py-1 uppercase tracking-wider	">
          Folder
        </label>
        <FolderSelectInput
          name="folderSelect"
          value={selectedFolder}
          onChange={handleFolderChange}
          options={[
            { value: "folder1", label: "Folder 1" },
            { value: "folder1_sub1", label: "Folder 1 / Subfolder 1" },
            { value: "folder1_sub2", label: "Folder 1 / Subfolder 2" },
            { value: "folder1_sub3", label: "Folder 1 / Subfolder 3" },
            { value: "folder2", label: "Folder 2" },
            { value: "folder2_sub1", label: "Folder 2 / Subfolder 1" },
            { value: "folder2_sub2", label: "Folder 2 / Subfolder 2" },
          ]}
          className="
 "
        />
      </div>

      <div className="mb-2 w-full  gap-4 items-center pt-3">
        <label className="block text-gray-700 text-sm font-normal	px-1 pt-1.5 pb-1 uppercase tracking-wider	">
          File Name
        </label>
        <SelectInput
          name="filename"
          value={gmailConfig.filename}
          options={[{ value: "monthlyexpenses", label: "Monthly Expenses" }]}
          onChange={handleChangeSelect}
          className="w-1/2"
        />
      </div>

      {/* <div className="flex items-center gap-4 my-4">
        <label className="text-sm font-medium text-gray-700 w-1/4">
          Spreadsheet
        </label>
        <SelectInput
          name="spreadsheet"
          value={gmailConfig.spreadsheet}
          options={[{ value: "sheet1", label: "sheet 1" }]}
          onChange={handleChangeSelect}
          className="w-1/2"
        />
      </div> */}

      <div className="flex flex-col mb-4 mt-6">
        <label className="text-gray-700 text-sm font-normal uppercase tracking-wider mb-4">
          Output Options
        </label>
        <div className="flex flex-col items-start place-content-start gap-4 mx-2">
          <label className="flex items-center">
            <input
              type="radio"
              name="outputOption"
              value="append"
              checked={gmailConfig.outputOption === "append"}
              onChange={handleChange}
              className="mr-2 w-5 h-5 cursor-pointer"
            />
            <span className="mx-1 text-black text-base font-light">
              Append output to document
            </span>
          </label>
          <label className="flex items-center">
            <input
              type="radio"
              name="outputOption"
              value="replace"
              checked={gmailConfig.outputOption === "replace"}
              onChange={handleChange}
              className="mr-2 w-5 h-5 cursor-pointer"
            />
            <span className="mx-1 text-black text-base font-light">
              Replace document with output{" "}
            </span>
          </label>
        </div>
      </div>

      <div className="flex items-center gap-2 mb-6 mt-8">
        <input
          type="checkbox"
          name="customizeOutput"
          checked={gmailConfig.customizeOutput}
          onChange={handleChange}
          className="mr-1 w-5 h-5  cursor-pointer"
        />
        <label className="text-gray-700 text-sm font-normal uppercase tracking-wider relative top-0.5">
          Customize output
        </label>
      </div>

      {gmailConfig.customizeOutput && (
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-normal uppercase tracking-wider mb-2">
            Output Details
          </label>
          <textarea
            name="customizedText"
            value={gmailConfig.customizedText}
            onChange={handleChange}
            className="w-full h-24 px-3 py-2 border border-gray-300 rounded-md"
            placeholder="<client_name>                              Campaign Performance&#13;&#10;Report Date:&#13;&#10;<findings>"
          />
        </div>
      )}

      <div className=" gap-4 mb-2">
        <label className="block text-gray-700 text-sm font-normal px-1 pt-2.5 pb-1 uppercase tracking-wider">
          Tags created
        </label>
        <TagsInput
          tags={gmailConfig.tags}
          setTags={setTags}
          className="w-full"
        />
      </div>

      <div className="flex items-center gap-4 my-2">
        <FormInputSource
          type="text"
          label="Description"
          name="description"
          value={gmailConfig.description}
          onChange={handleChange}
          className="w-full"
        />
      </div>
    </div>
  );
};

export default GoogleDocInputLayout;
