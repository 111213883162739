import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAiWorkers } from '../../services/api/apiService';

export const getAiWorkers = createAsyncThunk('aiWorkers/getAiWorkers', async () => {
  const response = await fetchAiWorkers();
  return response;
});

const aiWorkersSlice = createSlice({
  name: 'aiWorkers',
  initialState: {
    workers: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    addWorker: (state, action) => {
      const newWorker = {
        id: state.workers.length + 1,
        ...action.payload,
      };
      state.workers.push(newWorker);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAiWorkers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAiWorkers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.workers = action.payload;
      })
      .addCase(getAiWorkers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { addWorker } = aiWorkersSlice.actions;
export default aiWorkersSlice.reducer;
