import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addAccount,
  setOauthToken,
} from "../../../../redux/slices/gmailAccountsSlice";
import GooglePickerComponent from "../GooglePickerComponent";
import { jwtDecode } from "jwt-decode";
import SelectInput from "../../../Ui/Select/SelectInput";
import FormInputSource from "../../../Ui/Input/FormInputSource";
import googleIcon from "../../../../assets/images/google-icon.png";

const GoogleDriveInputLayout = ({ onFieldChange }) => {
  const dispatch = useDispatch();
  const existingAccounts = useSelector(
    (state) => state.gmailAccounts.existingAccounts
  );
  const oauthToken = useSelector((state) => state.gmailAccounts.oauthToken);

  const [gmailConfig, setGmailConfig] = useState({
    connection: "",
    from: "",
    to: "",
    subject: "",
    description: "",
    folderOption: "entire", // default value for the radio options
    previousDays: "", // value for the "Files from previous" select option
  });

  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const handleFieldChange = useCallback(onFieldChange, [onFieldChange]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGmailConfig((prevConfig) => ({ ...prevConfig, [name]: value }));
  };

  useEffect(() => {
    if (handleFieldChange) {
      handleFieldChange("Gmail", gmailConfig);
    }
  }, [gmailConfig, handleFieldChange]);

  const handleAccountSelection = (e) => {
    const { value } = e.target;
    setGmailConfig((prevConfig) => ({ ...prevConfig, connection: value }));
  };

  const handleGoogleLogin = () => {
    window.google.accounts.id.initialize({
      client_id:
        "508080835431-s9ovoa2d5lkf4i3m3glj8tf3rppbosdm.apps.googleusercontent.com",
      callback: handleCredentialResponse,
    });
    window.google.accounts.id.prompt(); // Display the One Tap prompt or the pop-up dialog
  };

  const handleCredentialResponse = (response) => {
    const userObject = jwtDecode(response.credential);
    dispatch(addAccount(userObject.email)); // Add the account to Redux
    dispatch(setOauthToken(response.credential)); // Store the token in Redux
  };

  const handleFileSelected = (fileId) => {
    console.log("Selected file ID:", fileId);
    // Handle the selected file ID as needed
  };

  const handleFileInputClick = () => {
    if (oauthToken) {
      setIsPickerOpen(true); // Set the picker to open
    }
  };

  const handleFolderOptionChange = (e) => {
    const { value } = e.target;
    setGmailConfig((prevConfig) => ({ ...prevConfig, folderOption: value }));
  };

  const handlePreviousDaysChange = (e) => {
    const { value } = e.target;
    setGmailConfig((prevConfig) => ({ ...prevConfig, previousDays: value }));
  };

  return (
    <div className="flex flex-col p-5 bg-white inner-page-bg-input">
      <div className="mb-4 w-full w-2/3 ">
        <label className="block text-gray-700 text-sm font-normal  px-1 pt-2.5  uppercase tracking-wider">
          Connection
        </label>
        {existingAccounts.length > 0 ? (
          <SelectInput
            name="connection"
            className="mt-1 w-full p-2.5 h-11 border border-slate-300 
 focus:outline-none focus:shadow-outline rounded-md text-sm font-normal shadow-sm"
            value={gmailConfig.connection}
            options={existingAccounts.map((account) => ({
              value: account,
              label: account,
            }))}
            onChange={handleAccountSelection}
          />
        ) : (
          <button
            type="button"
            onClick={handleGoogleLogin}
            className="w-full px-3.5 h-10 border border-[#e1e1e1] text-black flex items-center
  rounded-md text-base  bg-[#fafbff] place-content-center"
          >
            <img
              src={googleIcon}
              alt="icon"
              className="w-5 h-5 object-cover p-0.5 mx-1"
            />
            <span className="px-1">Connect to a New Account</span>
          </button>
        )}
      </div>

      {oauthToken && (
        <>
          <div className="flex items-center gap-4">
            <div className="w-1/2">
              <label className="block text-gray-700 text-sm font-normal  px-1 pt-2.5 pb-1 uppercase tracking-wider">
                Folder
              </label>
              <button
                type="button"
                onClick={handleFileInputClick}
                className="px-4 py-2 bg-gray-500 text-white rounded-md w-full"
              >
                Select a Folder
              </button>
            </div>

            {isPickerOpen && (
              <GooglePickerComponent
                oauthToken={oauthToken}
                onFileSelected={(fileId) => {
                  handleFileSelected(fileId);
                  setIsPickerOpen(false); // Close the picker after selection
                }}
              />
            )}
          </div>

          <div className="flex flex-col gap-2 mt-4">
            <label className="block text-gray-700 text-sm font-normal px-1 pt-2.5 pb-1 uppercase tracking-wider">
              Options
            </label>
            <div className="flex flex-col items-start place-content-start gap-4 mt-1 mb-2">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="folderOption"
                  value="entire"
                  checked={gmailConfig.folderOption === "entire"}
                  onChange={handleFolderOptionChange}
                  className="mx-2 w-5 h-5 cursor-pointer"
                />
                Entire Folder
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="folderOption"
                  value="previous"
                  checked={gmailConfig.folderOption === "previous"}
                  onChange={handleFolderOptionChange}
                  className="mx-2  w-5 h-5 cursor-pointer"
                />
                Files from previous
              </label>
              {gmailConfig.folderOption === "previous" && (
                <SelectInput
                  name="previousDays"
                  value={gmailConfig.previousDays}
                  options={[
                    { value: "1", label: "1 Day" },
                    { value: "7", label: "7 Days" },
                    { value: "30", label: "30 Days" },
                  ]}
                  onChange={handlePreviousDaysChange}
                  className="ml-2"
                />
              )}
            </div>
          </div>

          <div className="mb-6 mt-3">
            <FormInputSource
              label="Description"
              type="text"
              name="description"
              value={gmailConfig.description}
              onChange={handleChange}
              className="w-full"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default GoogleDriveInputLayout;
