const AiWorkerOptions = [
  { value: "", label: "--Add AI Worker--" },

    { value: "manual", label: "--Select Manual Instruction--" },
    { value: "-", label: "--Template Ai Worker--" },
    { value: "itemsextractor", label: "Items Extractor" },
    { value: "*", label: "--Your Ai Woker--" },
    { value: "emailsummarizer", label: "Email Summarizer" },
    { value: ")", label: "Table Extracter" }
  ];
  
  export default AiWorkerOptions;