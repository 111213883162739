import React, { useState, useCallback, useEffect } from "react";
import FormInputSource from "../../../Ui/Input/FormInputSource";
import TagEditor from "../../../Ui/Input/TagEditor";
import TagsInput from "../../../Ui/Input/TagsInput";

const GoogleSheetWidgetLayout = ({ onFieldChange }) => {
  const [emailSplitter, setEmailSplitter] = useState(false);
  const [tagEditorEnabled, setTagEditorEnabled] = useState(false); // Toggle for showing Tag Editor
  const [description, setDescription] = useState("Emails from Project Y");

  const [gmailConfig, setGmailConfig] = useState({
    emailSplitter: false,
    tagEditorEnabled: false,
    description: "Emails from Project Y",
    tagsedit: [],
    tags: [],
  });

  const handleCheckboxChange = (field) => {
    setGmailConfig((prevConfig) => {
      const newConfig = { ...prevConfig, [field]: !prevConfig[field] };
      return newConfig;
    });
  };

  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;
    setGmailConfig((prevConfig) => ({
      ...prevConfig,
      description: newDescription,
    }));
  };

  const handleTagsChange = (tagsedit) => {
    setGmailConfig((prevConfig) => ({
      ...prevConfig,
      tagsedit: tagsedit,
    }));
  };

  const setTags = (newTags) => {
    setGmailConfig((prevConfig) => ({ ...prevConfig, tags: newTags }));
  };

  const handleFieldChange = useCallback(onFieldChange, [onFieldChange]);

  useEffect(() => {
    if (handleFieldChange) {
      handleFieldChange("GmailWidget", gmailConfig);
    }
  }, [gmailConfig, handleFieldChange]);

  return (
    <div className="flex flex-col gap-4 p-5 bg-white inner-page-bg-input">
      <div className="mt-1 px-1">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            checked={gmailConfig.emailSplitter}
            onChange={() => handleCheckboxChange("emailSplitter")}
            className="form-checkbox h-4 w-4 text-orange-500 relative -top-0.5"
          />
          <span className="ml-2 text-gray-700 text-lg">
            <b className="text-black text-base font-semibold px-1 uppercase tracking-wider">
              Output Splitter:
            </b> <span className="font-light">
            AI to process each Save each AI output as a separate email. </span>
          </span>
        </label>
      </div>

      <div className="mt-1 px-1">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            checked={gmailConfig.tagEditorEnabled}
            onChange={() => handleCheckboxChange("tagEditorEnabled")}
            className="form-checkbox h-4 w-4 text-orange-500 relative -top-0.5"
          />
          <span className="ml-2 text-gray-700 text-lg">
            <b className="text-black text-base font-semibold px-1 uppercase tracking-wider">
              Tag Editor:
            </b> <span className="font-light">
            Extract tagged info from AI output </span>
          </span>
        </label>
      </div>

      {gmailConfig.tagEditorEnabled && (
        <div className="mt-3 mb-6">
          <TagEditor onTagsChange={handleTagsChange} />
        </div>
      )}

      <div className=" gap-4 mb-2">
        <label className="block text-gray-700 text-sm font-normal px-1 pt-2.5 pb-1 uppercase tracking-wider">
          Tags
        </label>
        <TagsInput
          tags={gmailConfig.tags}
          setTags={setTags}
          className="w-full"
        />
      </div>

      <div className="mb-6 mt-3">
        <FormInputSource
          label="Description"
          type="text"
          id="description"
          value={gmailConfig.description}
          onChange={handleDescriptionChange}
          className="w-full"
        />
      </div>
    </div>
  );
};

export default GoogleSheetWidgetLayout;
