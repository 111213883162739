import React from "react";

const AlertDialog = ({ 
  isOpen, 
  onClose, 
  title, 
  children, 
  onConfirm, 
  onDiscard, 
  confirmText = "Confirm", 
  discardText = "Discard", 
  confirmColor = "bg-green-500", 
  discardColor = "bg-red-500"
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white w-full max-w-lg px-8 py-4 rounded-lg shadow-lg relative">
        <div className="flex justify-between items-center">
          <div className="flex flex-col items-center w-full mt-5">
            <svg className="h-8 w-8 text-red-500 m-auto w-full" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z"/>  
              <path d="M14 3v4a1 1 0 0 0 1 1h4" />  
              <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />  
              <path d="M10 12l4 4m0 -4l-4 4" />
            </svg>
            <h3 className="text-xl font-bold text-center w-full pt-4 pb-1">{title}</h3>
          </div>
          <button
            onClick={onClose}
            className="text-4xl text-gray-600 hover:text-gray-900 absolute right-2 top-0.5"
          >
            &times;
          </button>
        </div>
        <div className="text-center mb-8">{children}</div>
        <div className="my-4 text-right">
          <button
            onClick={onDiscard}
            className={`px-4 py-2 mr-2 ${discardColor} text-white rounded`}
          >
            {discardText}
          </button>
          <button
            onClick={onConfirm}
            className={`px-4 py-2 ${confirmColor} text-white rounded`}
          >
            {confirmText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AlertDialog;