import React from "react";
import GoogleIcon from "../../assets/images/google-icon.webp";
import { Link } from "react-router-dom";
import Logo from "../Common/Logo/Logo";
import Button from "../Common/Button/Button";
import FormInput from "../Common/FormInput/FormInput";
import LeftPanel from "../Inner/LeftPanel";

const Register = () => {
  return (
    <div className="flex justify-center md:items-center bg-white relative min-h-screen bg-img px-2">
      <div className="w-full max-w-7xl mx-auto md:flex">
        <div className="md:flex w-full items-start place-content-center py-10 lg:gap-20">
        <LeftPanel />
          <div className="md:w-[45%] w-full flex flex-col items-center relative bg-white p-4 md:px-12 md:py-10 rounded-lg">
          <div className="md:hidden block md:my-0 mt-6 mb-9">
              <Logo isMobile />
            </div>
            <h1 className="text-2xl md:text-3xl text-start w-full font-semibold">
              Create your account!
            </h1>
            <p className="text-start text-sm mb-8 py-1 block w-full text-gray-400">
              Create an account fill your detail
            </p>
            <div className="w-full">
              <Button className="text-black border-2 border-slate-300 w-full" icon={GoogleIcon}>
                Sign Up with Google
              </Button>
            </div>
            <div className="flex place-content-center items-center my-1">
              <div className="border-b bg-grey-100 md:w-38 lg:w-48 w-28"></div>
              <div className="text-base p-3 text-slate-300">Or</div>
              <div className="border-b bg-grey-100 md:w-38 lg:w-48 w-28"></div>
            </div>
            <form className="w-full">
              <FormInput label="Work Email" type="email" required />
              <div className="flex gap-5 w-full ">
                <FormInput label="First Name" type="text" required className="w-6/12" />
                <FormInput label="Last Name" type="text" required className="w-6/12" />
              </div>
              <FormInput label="Password" type="password" required />
              <div className="flex my-4 items-start">
                <input id="default-checkbox" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded relative top-0.5" />
                <p className="px-1 text-sm text-slate-500">
                  By clicking signing up, you agree to our
                  <Link to="/terms" className="text-blue-400 px-1">Terms and Conditions</Link>
                  and you have read our
                  <Link to="/privacy" className="text-blue-400 px-1">Privacy Policy</Link>
                </p>
              </div>
              <Link to="/login">
                <Button className="mt-7 bg-blue-500 text-white p-2 h-10 w-full">
                  Sign up
                </Button>
              </Link>
            </form>
            <p className="text-center text-sm mt-4 py-3 block w-full text-gray-400">
              Already have an account?{" "}
              <Link to="/login" className="text-blue-400">
                Sign in
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
