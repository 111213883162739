import React, { useState } from "react";
import FormInputSource from "../../../Ui/Input/FormInputSource";

const GmailWidgetLayout = () => {
  const [emailSplitter, setEmailSplitter] = useState(false);
  const [description, setDescription] = useState("Emails from Project Y");

  const handleCheckboxChange = () => {
    setEmailSplitter(!emailSplitter);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleButtonClick = () => {
    console.log("Email Splitter:", emailSplitter);
    console.log("Description:", description);
    // Your JavaScript API trigger logic here
  };

  return (
    <div className="flex flex-col gap-4 p-5 bg-white inner-page-bg-input">
      <div className="mt-2 px-1">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            checked={emailSplitter}
            onChange={handleCheckboxChange}
            className="form-checkbox h-4 w-4 text-orange-500 relative -top-0.5"
          />
          <span className="ml-2 text-gray-700 text-lg ">
            <b className="text-black text-base font-semibold px-1 uppercase tracking-wider"> Email Splitter :</b>
            <span className="font-light">  AI to process each
            email individually as input</span>
          </span>
        </label>
      </div>

      <div className="mb-6 mt-5">
        <FormInputSource
          label="Description"
          type="text"
          id="description"
          value={description}
          onChange={handleDescriptionChange}
          className="w-full "
        />
      </div>
    </div>
  );
};

export default GmailWidgetLayout;
