import React, { useState } from "react";
import FolderSelectInput from "../../../Ui/Select/FolderSelectInput";
import FormInputSource from "../../../Ui/Input/FormInputSource";

const GoogleDocWidgetLayout = () => {
  const [emailSplitter, setEmailSplitter] = useState(false);
  const [description, setDescription] = useState("");
  const [fileMover, setFileMover] = useState(false);
  const [moveOption, setMoveOption] = useState("folder");
  const [selectedFolder, setSelectedFolder] = useState("");

  const handleCheckboxChange = (setter) => () => {
    setter((prevState) => !prevState);
  };

  const handleMoveOptionChange = (e) => {
    setMoveOption(e.target.value);
  };

  const handleFolderChange = (e) => {
    setSelectedFolder(e.target.value);
  };

  return (
    <div className="flex flex-col gap-4 p-5 bg-white inner-page-bg-input">
      <div className="my-3">
        <label className="flex  items-center gap-2 relative -top-0.5">
          <input
            type="checkbox"
            checked={emailSplitter}
            onChange={handleCheckboxChange(setEmailSplitter)}
            className="form-checkbox h-4 w-4 text-orange-500  relative -top-0.5 cursor-pointer"
          />
          <span className="ml-2 text-gray-700 text-lg ">
            <b className="text-black text-base font-semibold px-1 uppercase tracking-wider">
              {" "}
              Email Splitter :
            </b>
            <span className="font-light">
            AI to process each email individually as input
              </span>
          </span>
        </label>
      </div>

      <div className="mb-2 ">
        <label className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={fileMover}
            onChange={handleCheckboxChange(setFileMover)}
            className="form-checkbox h-4 w-4 text-orange-500 relative -top-0.5 cursor-pointer"
          />
          <span className="ml-2 text-gray-700 text-lg">File Mover</span>
        </label>
        {fileMover && (
          <>
            <div className="mt-3 pl-8">
              <div className="mb-2  px-1 py-1">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value="folder"
                    checked={moveOption === "folder"}
                    onChange={handleMoveOptionChange}
                    className="form-radio h-4 w-4 text-orange-500 relative -top-0.5"
                  />
                  <span className="ml-2 text-black text-base ">
                    Move to Folder
                  </span>
                </label>
              </div>
              <div className="mb-2 px-1 py-1">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value="bin"
                    checked={moveOption === "bin"}
                    onChange={handleMoveOptionChange}
                    className="form-radio h-4 w-4 text-orange-500  relative -top-0.5"
                  />
                  <span className="ml-2 text-black text-base ">
                    Move to Bin
                  </span>
                </label>
              </div>
            </div>

            {moveOption === "folder" && (
              <FolderSelectInput
                name="folderSelect"
                value={selectedFolder}
                onChange={handleFolderChange}
                options={[
                  { value: "folder1", label: "Folder 1" },
                  { value: "folder1_sub1", label: "Folder 1 / Subfolder 1" },
                  { value: "folder1_sub2", label: "Folder 1 / Subfolder 2" },
                  { value: "folder1_sub3", label: "Folder 1 / Subfolder 3" },

                  { value: "folder2", label: "Folder 2" },
                  { value: "folder2_sub1", label: "Folder 2 / Subfolder 1" },
                  { value: "folder2_sub2", label: "Folder 2 / Subfolder 2" },
                ]}
              />
            )}
          </>
        )}
      </div>

      <div className="mb-6 mt-5">
        <FormInputSource
          label="Description"
          type="text"
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="w-full "
        />
      </div>
    </div>
  );
};

export default GoogleDocWidgetLayout;
