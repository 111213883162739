import { initialAiWorkers, initialUsers, initialAiWorkflows } from "../../assets/data/data";

export const fetchAiWorkers = async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(initialAiWorkers);
    }, 1000); 
  });
};

export const fetchUsers = async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(initialUsers);
    }, 1000); 
  });
};

export const fetchAiWorkflows= async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(initialAiWorkflows);
    }, 1000); 
  });
};

// Mock API for deleting an AI workflow
export const deleteAiWorkflowApi = async (id) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      // Assuming `initialAiWorkflows` is a global variable or imported from elsewhere
      const updatedWorkflows = initialAiWorkflows.filter((workflow) => workflow.id !== id);
      // Simulate an API request by resolving the updated list after a delay
      resolve(updatedWorkflows);
    }, 1000); 
  });
};


export const saveAiWorkflowApi = async (workflowData) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      // const { id } = workflowData;

      // if (id) {
      //   // Update existing workflow
      //   const workflowIndex = initialAiWorkflows.findIndex((workflow) => workflow.id === id);
      //   if (workflowIndex !== -1) {
      //     const updatedWorkflow = { ...initialAiWorkflows[workflowIndex], ...workflowData };
      //     initialAiWorkflows[workflowIndex] = updatedWorkflow;
      //     resolve(updatedWorkflow);
      //   } else {
      //     reject(new Error("Workflow not found"));
      //   }
      // } else {
      //   // Create new workflow
      //   const newWorkflow = { ...workflowData, id: Date.now() }; // Generate a new ID
      //   initialAiWorkflows.push(newWorkflow);
      //   resolve(newWorkflow);
      // }
    }, 1000); // Simulate a delay
  });
};
