import React from "react";

const TemplateAiWorkerLayout = ({ onChange }) => {
  const handleInputChange = (event) => {
    onChange("manualInstruction", event.target.value);
  };

  return (
    <div>
      <h4 className="text-xl font-semibold mb-4">Manual Instruction</h4>
      <textarea
        className="w-full h-40 p-2 border border-gray-300 rounded-lg"
        placeholder="Enter your manual instructions..."
        onChange={handleInputChange}
      />
    </div>
  );
};

export default TemplateAiWorkerLayout;
