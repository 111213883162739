import React from "react";

const DateRange = ({ fromDate, toDate, onFromDateChange, onToDateChange }) => {
  return (
    <div className="flex flex-col mb-6">
      <div className="flex justify-between">
        <div className="w-1/2 pr-2">
          <label className="block text-gray-700 text-sm font-normal px-1 uppercase tracking-wider">
            From
          </label>
          <input
            type="date"
            value={fromDate}
            onChange={onFromDateChange}
            className="w-full px-3.5 h-10 border border-gray-300 rounded-md"
          />
        </div>
        <div className="w-1/2 pl-2">
          <label className="block text-gray-700 text-sm font-normal px-1 uppercase tracking-wider">
            To
          </label>
          <input
            type="date"
            value={toDate}
            onChange={onToDateChange}
            className="w-full px-3.5 h-10 border border-gray-300 rounded-md"
          />
        </div>
      </div>
    </div>
  );
};

export default DateRange;
