import React from "react";
import Logo from "../Common/Logo/Logo";

const LeftPanel = () => {
  return (
    <div className="md:w-[55%] w-full flex flex-col md:block hidden">
      <div className="relative w-full md:pr-20">
        <Logo />
        <div className="pt-32">
          <h3 className="text-slate-50 font-light text-start Nunito-font py-5 text-sm tracking-wider">
            START FOR FREE
          </h3>
          <h1 className="text-slate-50 font-semibold  md:text-3xl lg:text-6xl text-start tracking-wider
         dm-font">
            Effortless Automation Let
            <span className="text-sky-500 font-extrabold "> AI</span> do the work for you
          </h1>
        </div>
      </div>
    </div>
  );
};

export default LeftPanel;
