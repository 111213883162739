import React, { useState, useEffect, useCallback } from "react";
import FormInputSource from "../../../Ui/Input/FormInputSource";
import BasicDialog from "../../../Ui/Dialogs/basic-dailog";

const WebPageInputLayout = ({ onFieldChange }) => {
  const [webpageConfig, setWebpageConfig] = useState({
    url: "",
    description: "",
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleFieldChange = useCallback(onFieldChange, [onFieldChange]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setWebpageConfig((prevConfig) => ({ ...prevConfig, [name]: value }));
  };

  useEffect(() => {
    if (handleFieldChange) {
      handleFieldChange("url", webpageConfig);
    }
  }, [webpageConfig, handleFieldChange]);

  const toggleDialog = () => {
    setIsDialogOpen((prev) => !prev);
  };

  return (
    <div className="flex flex-col p-5 bg-white inner-page-bg-input">
      <div className="mb-6 mt-3">
        <FormInputSource
          label="URL"
          type="text"
          name="url"
          value={webpageConfig.url}
          onChange={handleChange}
          className="w-full"
        />
        <button
          onClick={toggleDialog}
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
        >
          Check URL
        </button>
      </div>

      <div className="mb-6 mt-3">
        <FormInputSource
          label="Description"
          type="text"
          name="description"
          value={webpageConfig.description}
          onChange={handleChange}
          className="w-full"
        />
      </div>

      <BasicDialog
        isOpen={isDialogOpen}
        onClose={toggleDialog}
        title="URL Preview"
      >
        <div className="p-4">
          {webpageConfig.url ? (
            <iframe
              src={webpageConfig.url}
              title="URL Preview"
              className="w-full h-[400px] border"
            />
          ) : (
            <p>No URL provided</p>
          )}
        </div>
      </BasicDialog>
    </div>
  );
};

export default WebPageInputLayout;
