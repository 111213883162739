import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import Logout from './pages/Logout';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import AiworkersPage from './pages/AiworkersPage';
import Aiworkflows from './pages/Aiworkflows';
import AiSequence from './components/AiSequence/AiSequence';
import AiWorkflowHisory from './components/AiWorkflowHistory/AiWorkflowHistory';
import AiSettings from './components/AiSettings/AiSettings';
import UsersPage from './pages/UsersPage';

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/users" element={<UsersPage/>} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/workflows" element={<Aiworkflows />} />
        <Route path="/ai-workers" element={<AiworkersPage />} />
        <Route path="/ai-sequence" element={<AiSequence />} />
        <Route path="/ai-workflow-history" element={<AiWorkflowHisory/>} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/" element={<LoginPage />} />
        <Route path="/ai-setting" element={<AiSettings />} />
      </Routes>
    </Router>
  );
}

export default App;
