import React, { useState, useCallback } from "react";
import Modal from "../Ui/Dialogs/dialogs";
import { TabContent } from "./TabContent";
import destinationOptions from "../../assets/data/destination";

const AddDestination = ({ isModalOpen, toggleModal }) => {
  const [activeTab, setActiveTab] = useState("Input");
  const [selectedSource, setSelectedSource] = useState("");
  const [formData, setFormData] = useState({});
  const [selectedLabel, setSelectedLabel] = useState(""); // New state to store the selected label

  const handleTabClick = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
 
  const handleSourceChange = (event) => {
    const selected = event.target.value;
    const selectedOption = destinationOptions.find((option) => option.value === selected);
    if (selectedSource !== selected) {
      setSelectedSource(selected);
      setSelectedLabel(selectedOption ? selectedOption.label : ""); // Set the selected label
    }
  };

  const handleFieldChange = useCallback((source, data) => {
    setFormData((prevData) => ({ ...prevData, [source]: data }));
  }, []);

  const handleSave = () => {
    console.log("Form Data Saved: ", formData);
    // Add logic to handle form data submission, e.g., API call
  };

  return (
    <Modal
      isOpen={isModalOpen}
      toggle={toggleModal}
      Maintitle="AIWorker"
      Subtitle="Add Workflows"
    >
      
      <div className="flex place-content-between pt-2 pb-4 bg-[#f9f8f2] px-4 md:px-5 border-b border-gray-200 ">
        <div className="flex flex-col items-start pt-2 w-2/5 ">
          <div id="source">
            <h3 className="text-3xl font-bold text-lime-800	 w-full text-start flex items-center  ">
              Add Destination
            </h3> 
          </div>
          <select
             className="w-full p-2 rounded-lg h-12 mt-2.5 pr-10 border border-gray-400 bg-gray-50"
              onChange={handleSourceChange}
              value={selectedSource}
            >
              {destinationOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
         </div>
         
        </div>
        <div className="w-full mx-auto p-6">
       
        {selectedSource && (
          <div  className="bg-white">
            <div className="tabs flex border-b border-gray-200 bg-[#f2f7f9] rounded-t-lg">
              <button
                className={` text-center px-14 py-2.5 text-base ${
                  activeTab === "Input"
                    ? "border	border-[#041749] text-white font-semibold bg-[#041749] rounded-tl-lg"
                    : "border border-gray-200 bg-[#d3d7e3] font-normal text-blue-900 rounded-tl-lg"
                }`}
                onClick={() => handleTabClick("Input")}
              >Output
              </button>
              
              <button
                className={` text-center px-14 py-2.5 text-base  ${
                  activeTab === "Widget"
                    ? "border	border-[#041749] text-white font-semibold bg-[#041749] rounded-tr-lg" 
                    : "border border-gray-200 bg-[#d3d7e3] font-normal text-blue-900 rounded-tr-lg"
                }`} 
                onClick={() => handleTabClick("Widget")}
              >
                Widget
              </button>
            </div>
      
            <div className="transition-opacity duration-300 ease-in-out">
            <div className="text-2xl text-gray-600 font-semibold bg-white px-6 pt-6">
              {selectedLabel} {/* Display the selected label here */}
            </div>
              <TabContent
                source={selectedSource}
                activeTab={activeTab}
                onFieldChange={handleFieldChange}
              />
            </div>
            <div className="flex justify-end pt-1 p-8 bg-white">
          <button 
            onClick={handleSave}
            className="px-8 py-2 bg-green-500 text-white rounded-3xl hover:bg-green-600"
          >Save
          </button>
        </div>
          </div>
        )}
       
      </div>
    </Modal>
  );
};

export default AddDestination;
