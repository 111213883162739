import React from "react";
import ForgotPassword from "../components/ForgotPassword/ForgotPassword";

const ForgotPasswordPage = () => {
  return (
    <div>
      <ForgotPassword />
    </div>
  );
};

export default ForgotPasswordPage;
