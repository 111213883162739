import React from "react";
import { NavLink } from "react-router-dom";
const Sidebar = () => {
  return (
    <div className="h-full  bg-[#041749] text-white w-16 lg:w-56">
      <div className="fixed  bg-[#041749] w-[18%] sm:w-16 lg:w-56 h-full flex flex-col place-content-between">
        <div>
          <NavLink to="/" className="cursor-pointer">
            <h1 className="text-slate-50 font-bold text-2xl text-start p-3.5 flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7.864 4.243A7.5 7.5 0 0 1 19.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 0 0 4.5 10.5a7.464 7.464 0 0 1-1.15 3.993m1.989 3.559A11.209 11.209 0 0 0 8.25 10.5a3.75 3.75 0 1 1 7.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 0 1-3.6 9.75m6.633-4.596a18.666 18.666 0 0 1-2.485 5.33"
                />
              </svg>
              <span className="hidden lg:block">Ai Automation</span>
            </h1>
          </NavLink>

          <ul className="mt-5">
            <li className="flex mb-3.5">
              <NavLink
                to="/workflows"
                className={({ isActive }) =>
                  `p-2 flex items-center w-full text-base ${
                    isActive
                      ? "bg-indigo-50 font-semibold text-blue-600 rounded-l-3xl	"
                      : "text-sate-300"
                  }`
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-4 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 6a7.5 7.5 0 1 0 7.5 7.5h-7.5V6Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 10.5H21A7.5 7.5 0 0 0 13.5 3v7.5Z"
                  />
                </svg>
                <span className="hidden lg:block">Workflows</span>
              </NavLink>
            </li>
            <li className="flex mb-3.5">
              <NavLink
                to="/ai-sequence"
                className={({ isActive }) =>
                  `p-2 flex items-center w-full text-base ${
                    isActive
                      ? "bg-indigo-50 font-semibold	text-blue-600 rounded-l-3xl	"
                      : "text-slate-300"
                  }`
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-4 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
                  />
                </svg>
                <span className="hidden lg:block">Sequences</span>
              </NavLink>
            </li>
            <li className="flex mb-3.5">
              <NavLink
                to="/ai-workers"
                className={({ isActive }) =>
                  `p-2 flex items-center w-full text-base ${
                    isActive
                      ? "bg-indigo-50 font-semibold	text-blue-600 rounded-l-3xl	"
                      : "text-slate-300"
                  }`
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-4 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                  />
                </svg>

                <span className="hidden lg:block">AI Workers</span>
              </NavLink>
            </li>

            <li className="flex mb-3.5">
              <NavLink
                to="/ai-workflow-history"
                className={({ isActive }) =>
                  `p-2 flex items-center w-full text-base ${
                    isActive
                      ? "bg-indigo-50 font-semibold	text-blue-600 rounded-l-3xl	"
                      : "text-slate-300"
                  }`
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-4 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                  />
                </svg>

                <span className="hidden lg:block">History</span>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="md:h-auto h-full pb-4">
          <ul className="mt-5">

          <li className="flex mb-3.5">
              <NavLink
                to="/users"
                className={({ isActive }) =>
                  `p-2 flex items-center w-full text-base ${
                    isActive
                      ? "bg-indigo-50 font-semibold	text-blue-600 rounded-l-3xl	"
                      : "text-slate-300"
                  }`
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-5 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12a7.5 7.5 0 0 0 15 0m-15 0a7.5 7.5 0 1 1 15 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077 1.41-.513m14.095-5.13 1.41-.513M5.106 17.785l1.15-.964m11.49-9.642 1.149-.964M7.501 19.795l.75-1.3m7.5-12.99.75-1.3m-6.063 16.658.26-1.477m2.605-14.772.26-1.477m0 17.726-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205 12 12m6.894 5.785-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495"
                  />
                </svg>
                <span className="hidden lg:block">Users</span>
              </NavLink>
            </li>

            <li className="flex mb-3.5">
              <NavLink
                to="/ai-setting"
                className={({ isActive }) =>
                  `p-2 flex items-center w-full text-base ${
                    isActive
                      ? "bg-indigo-50 font-semibold	text-blue-600 rounded-l-3xl	"
                      : "text-slate-300"
                  }`
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-5 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12a7.5 7.5 0 0 0 15 0m-15 0a7.5 7.5 0 1 1 15 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077 1.41-.513m14.095-5.13 1.41-.513M5.106 17.785l1.15-.964m11.49-9.642 1.149-.964M7.501 19.795l.75-1.3m7.5-12.99.75-1.3m-6.063 16.658.26-1.477m2.605-14.772.26-1.477m0 17.726-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205 12 12m6.894 5.785-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495"
                  />
                </svg>
                <span className="hidden lg:block">Settings</span>
              </NavLink>
            </li>
            <li className="flex">
              <NavLink
                to="/logout"
                className={({ isActive }) =>
                  `p-2 flex items-center w-full text-base ${
                    isActive
                      ? "bg-[#f3f4f6] text-black rounded-l-3xl	"
                      : "text-slate-300"
                  }`
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-5 mr-2 rotate-180"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15"
                  />
                </svg>
                <span className="hidden lg:block">Logout</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
