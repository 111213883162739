import React, { useState, useEffect, useCallback } from "react";
import FormInputSource from "../../../Ui/Input/FormInputSource";

const YoutubeInputLayout = ({ onFieldChange }) => {
  const [youtubeConfig, setYoutubeConfig] = useState({
    youtubeUrls: "",
    description: "",
    mediaType: "video", // default to "video"
  });

  const handleFieldChange = useCallback(onFieldChange, [onFieldChange]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type === "radio") {
      setYoutubeConfig((prevConfig) => ({ ...prevConfig, mediaType: value }));
    } else {
      setYoutubeConfig((prevConfig) => ({ ...prevConfig, [name]: value }));
    }
  };
 
  useEffect(() => {
    if (handleFieldChange) {
      handleFieldChange("rssurl", youtubeConfig);
    }
  }, [youtubeConfig, handleFieldChange]);

  return (
    <div className="flex flex-col p-5 bg-white inner-page-bg-input">
      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-normal  px-1 pt-2.5 pb-1 uppercase tracking-wider" htmlFor="youtubeUrls">
          YouTube URLs <b>(one per line)</b>
        </label>
        <textarea
          name="youtubeUrls"
          value={youtubeConfig.youtubeUrls}
          onChange={handleChange}
          className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
          rows="4"
          placeholder="Enter one URL per line"
        />
      </div>

      <div className="mb-1">
      <label className="block text-gray-700 text-sm font-normal  px-1  pb-3 uppercase tracking-wider">
      Media Type
        </label>
        <div className="flex items-start place-content-start flex-col">
          <label className="flex items-center">
          <input
            type="radio"
            id="audio"
            name="mediaType"
            value="audio"
            checked={youtubeConfig.mediaType === "audio"}
            onChange={handleChange}
            className="mx-2 w-5 h-5 cursor-pointer"
          />
          <span htmlFor="audio" className="mr-6">Audio Only</span>
          </label>
          <label className="flex items-center my-4">

          <input
            type="radio"
            id="video"
            name="mediaType"
            value="video"
            checked={youtubeConfig.mediaType === "video"}
            onChange={handleChange}
            className="mx-2  w-5 h-5 cursor-pointer"
          />
          <span htmlFor="video pt-1">Video </span>
          </label>
        </div>
      </div>

      <div className="mb-6 mt-2">
        <FormInputSource
          label="Description"
          type="text"
          name="description"
          value={youtubeConfig.description}
          onChange={handleChange}
          className="w-full"
        />
      </div>
    </div>
  );
};

export default YoutubeInputLayout;
