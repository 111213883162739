import React from "react";
import GmailInputLayout from "./InputLayout/GmailInputLayout";
import GmailWidgetLayout from "./WidgetLayout/GmailWidgetLayout";
import GoogleDocInputLayout from "./InputLayout/GoogleDocInputLayout";
import GoogleDocWidgetLayout from "./WidgetLayout/GoogleDocWidgetLayout";
import GoogleSheetInputLayout from "./InputLayout/GoogleSheetInputLayout";
import GoogleSheetWidgetLayout from "./WidgetLayout/GoogleSheetWidgetLayout";
import GoogleDriveInputLayout from "./InputLayout/GoogleDriveInputLayout";
import OneNoteInputLayout from "./InputLayout/OneNoteInputLayout";
import RssFeedInputLayout from "./InputLayout/RssFeedInputLayout";
import YoutubeInputLayout from "./InputLayout/YoutubeInputLayout";
import YoutubeWidgetLayout from "./WidgetLayout/YoutubeWidgetLayout";
import OutlookInputLayout from "./InputLayout/OutlookInputLayout";
import ApplePodcastsInputLayout from "./InputLayout/ApplePodcastsInputLayout";
import AppleWidgetLayout from "./WidgetLayout/AppleWidgetLayout";
import WebPageInputLayout from "./InputLayout/WebPageInputLayout";

const TabContent = ({ source, activeTab, onFieldChange }) => {
  if (source === "Gmail") {
    if (activeTab === "Input") {
      return <GmailInputLayout onFieldChange={onFieldChange} />;
    } else if (activeTab === "Widget") {
      return <GmailWidgetLayout />;
    }
  }
  if (source === "GoogleDocs") {
    if (activeTab === "Input") {
      return <GoogleDocInputLayout onFieldChange={onFieldChange} />;
    } else if (activeTab === "Widget") {
      return <GoogleDocWidgetLayout />;
    }
  }
  if (source === "GoogleSheets") {
    if (activeTab === "Input") {
      return <GoogleSheetInputLayout onFieldChange={onFieldChange} />;
    } else if (activeTab === "Widget") {
      return <GoogleSheetWidgetLayout />;
    }
  }
  if (source === "OneDrive") {
    if (activeTab === "Input") {
      return <GoogleDriveInputLayout onFieldChange={onFieldChange} />;
    } else if (activeTab === "Widget") {
      return <GoogleSheetWidgetLayout />;
    }
  }

  if (source === "Outlook") {
    if (activeTab === "Input") {
      return <OutlookInputLayout onFieldChange={onFieldChange} />;
    } else if (activeTab === "Widget") {
      return <GoogleSheetWidgetLayout />;
    }
  }

  if (source === "OneNote") {
    if (activeTab === "Input") {
      return <OneNoteInputLayout onFieldChange={onFieldChange} />;
    } else if (activeTab === "Widget") {
      return <GoogleSheetWidgetLayout />;
    }
  }
  if (source === "RSSFeed") {
    if (activeTab === "Input") {
      return <RssFeedInputLayout onFieldChange={onFieldChange} />;
    }
  }

  if (source === "YouTube") {
    if (activeTab === "Input") {
      return <YoutubeInputLayout onFieldChange={onFieldChange} />;
    } else if (activeTab === "Widget") {
      return <YoutubeWidgetLayout />;
    }
  }

  if (source === "Apple") {
    if (activeTab === "Input") {
      return <ApplePodcastsInputLayout onFieldChange={onFieldChange} />;
    } else if (activeTab === "Widget") {
      return <AppleWidgetLayout />;
    }
  }

  if (source === "Webpage") {
    if (activeTab === "Input") {
      return <WebPageInputLayout onFieldChange={onFieldChange} />;
    }
  }

  // Handle other sources similarly...
  return null;
};

export default TabContent;
