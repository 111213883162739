import { useEffect, useState, useCallback } from "react";

const GooglePickerComponent = ({ oauthToken, onFileSelected }) => {
  const [pickerApiLoaded, setPickerApiLoaded] = useState(false);

  const pickerCallback = useCallback((data) => {
    if (data.action === window.google.picker.Action.PICKED) {
      const fileId = data.docs[0].id;
      onFileSelected(fileId);
    }
  }, [onFileSelected]);

  useEffect(() => {
    const loadPickerApi = () => {
      const script = document.createElement("script");
      script.src = "https://apis.google.com/js/api.js";
      script.onload = () => {
        window.gapi.load("picker", {
          callback: () => {
            setPickerApiLoaded(true);
          },
        });
      };
      document.body.appendChild(script);
    };

    if (!window.gapi) {
      loadPickerApi();
    } else {
      window.gapi.load("picker", {
        callback: () => {
          setPickerApiLoaded(true);
        },
      });
    }
  }, []);

  useEffect(() => {
    if (pickerApiLoaded && oauthToken) {
      const picker = new window.google.picker.PickerBuilder()
        .addView(window.google.picker.ViewId.DOCS)
        .addView(window.google.picker.ViewId.SPREADSHEETS)
        .setOAuthToken(oauthToken)
        .setDeveloperKey("AIzaSyCYoWg8XCCsvppF8QOwnTnvPbbf4L6doRg")
        .setCallback(pickerCallback)
        .build();
      picker.setVisible(true);
    }
  }, [pickerApiLoaded, oauthToken, pickerCallback]);  // Add pickerCallback to the dependency array

  return null;
};

export default GooglePickerComponent;
