import React, { useState } from "react";
const Modal = ({ isOpen, toggle, Maintitle,  children }) => {
const [isClosing, setIsClosing] = useState(false);
  const [width, setWidth] = useState("max-w-4xl"); // Default width className
  const [isWide, setIsWide] = useState(false); // State to track current width
  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      toggle();
    }, 600); // Duration of the animation
  };

  const toggleWidth = () => {
    if (isWide) {
      setWidth("max-w-4xl"); // Decrease width className
    } else {
      setWidth("max-w-6xl"); // Increase width className
    }
    setIsWide(!isWide); // Toggle the width state
  };

  return (
    <div
      id="static-modal"
      data-modal-backdrop="static"
      tabIndex="-1"
      aria-hidden={!isOpen}
      className={`${
        isOpen ? "flex" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed right-0 h-full items-center z-50 justify-end 
      w-full md:inset-0 bg-black/[.60]`}
    >
      <div
        className={`relative w-full ${width} h-full transition-transform slow-transition ${
          isOpen && !isClosing
            ? "animate-slide-in-right"
            : "animate-slide-out-left" 
        }`}
      >
        <div className="relative w-full h-full">
          <div className="flex flex-col absolute -left-10 z-10">
            <button
              onClick={toggleWidth}
              className="text-gray-900 ml-auto inline-flex items-center w-full
               px-3 py-1.5  bg-[#60befa] text-2xl text-white pb-2 font-medium	"
            >
              {isWide ? "⇋" : "⇌"}
            </button>

            <button
              type="button"
              className="text-gray-900 ml-auto inline-flex items-center w-full
               px-3 py-1.5 bg-[#60befa] text-xl text-white pb-2 border-t"
              onClick={handleClose}
            >
X
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <div className="relative bg-[#f2f7f9] shadow  h-full z-20 overflow-scroll	">
              {/* <h1 className="text-gray-400 tracking-[0.7px]">{Maintitle}</h1> */}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
