import React, { useState, useEffect } from "react";

const EditUser = ({ isModalOpen, toggleModal, user, onSave }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    plan: "",
    sprintsUsed: "",
  });

  useEffect(() => {
    if (user) {
      setFormData({
        name: user.name,
        email: user.email,
        plan: user.plan || "",
        sprintsUsed: user.sprintsUsed || "",
      });
    }
  }, [user]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSave({ ...user, ...formData });
  };

  return (
    isModalOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg w-full md:w-[36%]">
          <div className="flex place-content-between	bg-[#f1f4f7] rounded-t-lg ">
            <h2 className="text-lg font-bold   bg-[#f1f4f7] py-4 px-12 rounded-t-lg text-center w-full">
              Edit User
            </h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              onClick={toggleModal}
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6 text-gray-500 cursor-pointer m-1 "
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </div>

          <form onSubmit={handleSubmit} className="pt-4 px-6">
            <label className="flex flex-col gap-0.5 mb-4">
              Name:
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="border rounded-lg p-2 w-full bg-cyan-50"
                required
              />
            </label>
            <label className="flex flex-col gap-0.5 mb-4">
              Email:
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="border rounded-lg p-2 w-full bg-cyan-50" 
                required
              />
            </label>
            <label className="flex flex-col gap-0.5 mb-4">
              Plan:
              <input
                type="text"
                name="plan"
                value={formData.plan}
                onChange={handleChange}
                className="border rounded-lg p-2 w-full bg-cyan-50"
              />
            </label>
            <label className="flex flex-col gap-0.5 mb-4">
              Sprints Used:
              <input
                type="number"
                name="sprintsUsed"
                value={formData.sprintsUsed}
                onChange={handleChange}
                className="border rounded-lg p-2 w-full bg-cyan-50"
              />
            </label>
            <div className="flex justify-end gap-2 my-10">
              <button
                type="button"
                onClick={toggleModal}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg mr-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-green-500 text-white px-6 py-2 rounded-md"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  );
};

export default EditUser;
