import React from "react";
import AiWorkers from "../components/AiWorker/AiWorkers";

const AiworkersPage = () => {
  return (
    <div>
      <AiWorkers />
    </div>
  );
};

export default AiworkersPage;
