import React, { useState, useCallback, useEffect } from "react";
import Modal from "../Ui/Dialogs/dialogs";
import { TabContent } from "./TabContent";
import sourceOptions from "../../assets/data/source";
import AlertDialog from "../Ui/Dialogs/AlertDialog";
import { useDispatch } from "react-redux";
import { createGmailFilter } from "../../redux/slices/gmailApiSlice";

const AddSource = ({ isModalOpen, toggleModal }) => {
  const [activeTab, setActiveTab] = useState("Input");
  const [selectedSource, setSelectedSource] = useState("");
  const [selectedLabel, setSelectedLabel] = useState("");
  const [formData, setFormData] = useState({});
  const [initialFormData, setInitialFormData] = useState({});
  const [isUnsaved, setIsUnsaved] = useState(false); // Track if there are unsaved changes
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false); // Track AlertDialog visibility
  
  const dispatch = useDispatch();
  const apiKey = 'ef8dee3a2c38b6ede3d7c22951da462a'; 
  // const { status, filterResponse, error } = useSelector((state) => state.gmailApi);

  // Handle tab click
  const handleTabClick = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  // Handle source change
  const handleSourceChange = (event) => {
    const selected = event.target.value;
    const selectedOption = sourceOptions.find((option) => option.value === selected);

    if (selectedSource !== selected) {
      setSelectedSource(selected);
      setSelectedLabel(selectedOption ? selectedOption.label : "");
    }
  };

  // Handle field change
  const handleFieldChange = useCallback((source, data) => {
    setFormData((prevData) => {
      const newFormData = { ...prevData, [source]: data };
      setIsUnsaved(JSON.stringify(newFormData) !== JSON.stringify(initialFormData));
      return newFormData;
    });
  }, [initialFormData]);

  // Handle Save
  const handleSave = () => {
    setInitialFormData(formData);
    setIsUnsaved(false);
    const filterData = {
      connection: 'super@gmail.com',
      from: 'ivan8wwww@qq.com',
      to: 'superwoman@gmail.com',
      subject: 'hello',
      label: 'label1,label2',
      days: 4,
      date: '2024-02-23',
      attach: 2,
      maxnum: 20,
      splitter: 1,
      desc: 'description',
    };

    console.log("Form Data Saved: ", filterData);


    dispatch(createGmailFilter({ apiKey, ...filterData }));
  };

  // Handle Modal close with confirmation dialog
  const handleModalClose = () => {
    if (isUnsaved) {
      setIsAlertDialogOpen(true);
    } else {
      toggleModal();
    }
  };

  // Handle confirm save
  const handleConfirmSave = () => {
    handleSave();
    toggleModal();
    setIsAlertDialogOpen(false);
  };

  // Handle discard changes
  const handleDiscardChanges = () => {
    setFormData(initialFormData);
    setIsUnsaved(false);
    toggleModal();
    setIsAlertDialogOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        toggle={handleModalClose}
        Maintitle="AIWorker"
        Subtitle="Add Workflows"
      >
        <div className="flex place-content-between pt-2 pb-4 bg-[#fcf9fa] px-4 md:px-5 border-b border-gray-200">
          <div className="flex flex-col items-start pt-2 w-2/5">
            <div id="source">
              <h3 className="text-3xl font-bold text-black w-full text-start flex items-center">
                Add Source
              </h3>
            </div>
            <select
              className="w-full p-2 rounded-lg h-12 mt-2.5 pr-10 border border-gray-400 bg-gray-50"
              onChange={handleSourceChange}
              value={selectedSource}
            >
              {sourceOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="w-full mx-auto p-6">
          {selectedSource && (
            <div className="bg-white rounded-t-lg">
              <div className="tabs flex border-b border-gray-200 bg-[#f2f7f9] rounded-t-lg">
                <button
                  className={`text-center px-14 py-2.5 text-base ${
                    activeTab === "Input"
                      ? "border	border-[#041749] text-white font-semibold bg-[#041749] rounded-tl-lg"
                      : "border border-gray-200 bg-[#d3d7e3] font-normal text-blue-900 rounded-tl-lg"
                  }`}
                  onClick={() => handleTabClick("Input")}
                >
                  Input
                </button>
                <button
                  className={`text-center px-14 py-2.5 text-base ${
                    activeTab === "Widget"
                      ? "border	border-[#041749] text-white font-semibold bg-[#041749] rounded-tr-lg"
                      : "border border-gray-200 bg-[#d3d7e3] font-normal text-blue-900 rounded-tr-lg"
                  }`}
                  onClick={() => handleTabClick("Widget")}
                >
                  Widget
                </button>
              </div>

              <div className="transition-opacity duration-300 ease-in-out">
                <div className="text-2xl text-gray-600 font-semibold bg-white px-6 pt-6">
                  {selectedLabel}
                </div>
                <TabContent
                  source={selectedSource}
                  activeTab={activeTab}
                  onFieldChange={handleFieldChange}
                />
              </div>
              <div className="flex justify-end pt-1 p-8 bg-white">
                <button
                  onClick={handleSave}
                  className="px-8 py-2 bg-green-500 text-white rounded-3xl hover:bg-green-600"
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>

      <AlertDialog
        isOpen={isAlertDialogOpen}
        onClose={() => setIsAlertDialogOpen(false)}
        title="You have unsaved changes"
        onConfirm={handleConfirmSave}
        onDiscard={handleDiscardChanges}
      >
        Do you want to save your changes before closing?
      </AlertDialog>
    </>
  );
};

export default AddSource;
