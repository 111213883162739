import React from "react";
import FormInputSource from "../../Ui/Input/FormInputSource";

const ManualInstructionLayout = ({ onChange }) => {
  const handleInputChange = (event) => {
    onChange("manualInstruction", event.target.value);
  };

  return (
    <div>
      <h4 className="text-xl font-semibold mb-4">Manual Instruction</h4>
      <FormInputSource
        name="manualInstruction"
        label="Instructions"
        type="textarea"
        required={true}
        value="" // You can pass the value from state if needed
        onChange={handleInputChange}
        className=""
      />
    </div>
  );
};

export default ManualInstructionLayout;
