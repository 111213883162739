import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  existingAccounts: [],
  oauthToken: null,
};

const gmailAccountsSlice = createSlice({
  name: 'gmailAccounts',
  initialState,
  reducers: {
    addAccount: (state, action) => {
      state.existingAccounts.push(action.payload);
    },
    setAccounts: (state, action) => {
      state.existingAccounts = action.payload;
    },
    setOauthToken: (state, action) => {
      state.oauthToken = action.payload;
    },
  },
});

export const { addAccount, setAccounts, setOauthToken } = gmailAccountsSlice.actions;
export default gmailAccountsSlice.reducer;
