import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormInputSource from "../../../Ui/Input/FormInputSource";
import {
  addAccount,
  setOauthToken,
} from "../../../../redux/slices/gmailAccountsSlice";
import { jwtDecode } from "jwt-decode";
import SelectInput from "../../../Ui/Select/SelectInput";
import googleIcon from "../../../../assets/images/google-icon.png";
import DateRange from "../../../Ui/Datepicker/DateRange";

const OutlookInputLayout = ({ onFieldChange }) => {
  const dispatch = useDispatch();
  const existingAccounts = useSelector(
    (state) => state.gmailAccounts.existingAccounts
  );

  const [gmailConfig, setGmailConfig] = useState({
    connection: "",
    to: "",
    cc: "",
    bcc: "",
    subject: "",
    description: "",
    folder: "", // Added folder state
    dateRangeOption: "workflow", // Added to manage selected date range option
    fromDate: "",
    toDate: "",
    maxPages: "20",
  });

  const handleFieldChange = useCallback(onFieldChange, [onFieldChange]);

  useEffect(() => {
    if (handleFieldChange) {
      handleFieldChange("Gmail", gmailConfig);
    }
  }, [gmailConfig, handleFieldChange]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGmailConfig((prevConfig) => ({ ...prevConfig, [name]: value }));
  };

  const handleAccountSelection = (e) => {
    const { value } = e.target;
    setGmailConfig((prevConfig) => ({ ...prevConfig, connection: value }));
  };

  const handleGoogleLogin = () => {
    window.google.accounts.id.initialize({
      client_id:
        "508080835431-s9ovoa2d5lkf4i3m3glj8tf3rppbosdm.apps.googleusercontent.com",
      callback: handleCredentialResponse,
    });
    window.google.accounts.id.prompt();
  };

  const handleCredentialResponse = (response) => {
    const userObject = jwtDecode(response.credential);
    dispatch(addAccount(userObject.email));
    dispatch(setOauthToken(response.credential));
  };

  return (
    <div className="flex flex-col p-5 bg-white inner-page-bg-input">
      <div className="mb-4 w-full w-2/3">
        <label className="block text-gray-700 text-sm font-normal px-1 pt-2.5 pb-1 uppercase tracking-wider">
          Connection
        </label>
        {existingAccounts.length > 0 ? (
          <SelectInput
            name="connection"
            className="w-full px-3.5 h-10 font-semibold"
            value={gmailConfig.connection}
            options={existingAccounts.map((account) => ({
              value: account,
              label: account,
            }))}
            onChange={handleAccountSelection}
          />
        ) : (
          <button
            type="button"
            onClick={handleGoogleLogin}
            className="w-full px-3.5 h-10 border border-[#e1e1e1] text-black flex items-center
            rounded-md text-base bg-[#fafbff] place-content-center"
          >
            <img
              src={googleIcon}
              alt="icon"
              className="w-5 h-5 object-cover p-0.5 mx-1"
            />
            <span className="px-1">Connect to a New Account</span>
          </button>
        )}
      </div>

      {[
        { label: "From", name: "from", type: "email" },
        { label: "To", name: "to", type: "email" },
        { label: "Cc", name: "cc", type: "text" },
        { label: "Subject", name: "subject", type: "text" },
      ].map((input, index) => (
        <div key={index} className="flex flex-col items-start ">
          <label className="block text-gray-700 text-sm font-normal px-1 relative top-3.5 pb-1 uppercase tracking-wider">
            {input.label}
          </label>
          <FormInputSource
            type={input.type}
            name={input.name}
            value={gmailConfig[input.name]}
            onChange={handleChange}
            required
            className="w-full p-0 m-0"
          />
        </div>
      ))}

      <div className="mb-6 mt-3">
        <label className="block text-gray-700 text-sm font-normal px-1 pt-2.5 pb-1 uppercase tracking-wider">
          Folder
        </label>
        <SelectInput
          name="folder"
          value={gmailConfig.folder}
          placeholder="Select a Folder"
          options={[
            { value: "inbox", label: "Inbox" },
            { value: "sent", label: "Sent" },
            { value: "drafts", label: "Drafts" },
          ]}
          onChange={handleChange}
        />
      </div>

      {/* Date Range Option */}
      <div className="mb-6 mt-3">
        <label className="block text-gray-700 text-sm font-normal px-1 pt-2.5 pb-1 uppercase tracking-wider">
          Date Range
        </label>
        <div className="flex flex-col mt-3">
          <label className="flex items-center mb-4">
            <input
              type="radio"
              name="dateRangeOption"
              value="workflow"
              checked={gmailConfig.dateRangeOption === "workflow"}
              onChange={handleChange}
              className="mx-2 form-radio h-5 w-5 text-indigo-600"
            />
            <span className="mx-1 text-black text-base font-light">Workflow Execution Date Only</span>
          </label>
          <label className="flex items-center">
            <input
              type="radio"
              name="dateRangeOption"
              value="range"
              checked={gmailConfig.dateRangeOption === "range"}
              onChange={handleChange}
              className="mx-2 form-radio h-5 w-5 text-indigo-600"
            />
            <span className="mx-1 text-black text-base font-light">Select Date Range</span>
          </label>
        </div>
      </div>

      {/* Conditionally render DateRange component based on selected option */}
      {gmailConfig.dateRangeOption === "range" && (
        <DateRange
          fromDate={gmailConfig.fromDate}
          toDate={gmailConfig.toDate}
          onFromDateChange={(e) =>
            setGmailConfig((prevConfig) => ({
              ...prevConfig,
              fromDate: e.target.value,
            }))
          }
          onToDateChange={(e) =>
            setGmailConfig((prevConfig) => ({
              ...prevConfig,
              toDate: e.target.value,
            }))
          }
        />
      )}

      <div className="flex items-center pt-4 pb-8 px-2 gap-2">
        <input
          type="checkbox"
          id="hasAttachment"
          className="form-checkbox w-5 h-5 cursor-pointer"
          defaultChecked
        />
        <label
          htmlFor="hasAttachment"
          className="block text-gray-700 text-sm font-normal px-1 pt-1.5 pb-1 uppercase tracking-wider"
        >
          has attachment
        </label>
      </div>

      <div className="flex flex-col items-start place-contenr-start gap-4 mb-4">
        <label className="block w-full text-gray-700 text-sm font-normal px-1 pt-1.5 uppercase tracking-wider">
          Maximum no. of emails
        </label>
        <SelectInput
          name="maxPages"
          value={gmailConfig.maxPages}
          options={[
            { value: "5", label: "5" },
            { value: "10", label: "10" },
            { value: "20", label: "20" },
          ]}
          onChange={handleChange}
          className="w-full"
        />
      </div>

      <div className="mb-6 mt-3">
        <FormInputSource
          type="text"
          label="Description"
          name="description"
          value={gmailConfig.description}
          onChange={handleChange}
          className="w-full"
        />
      </div>
    </div>
  );
};

export default OutlookInputLayout;
