import React from "react";
import GmailInputLayout from "./InputLayout/GmailInputLayout";
import GmailWidgetLayout from "./WidgetLayout/GmailWidgetLayout";
import GoogleDocInputLayout from "./InputLayout/GoogleDocInputLayout";
import GoogleDocWidgetLayout from "./WidgetLayout/GoogleDocWidgetLayout";
import GoogleSheetInputLayout from "./InputLayout/GoogleSheetInputLayout";
import GoogleSheetWidgetLayout from "./WidgetLayout/GoogleSheetWidgetLayout";
import GoogleDriveInputLayout from "./InputLayout/GoogleDriveInputLayout";
import OneNoteInputLayout from "./InputLayout/OneNoteInputLayout";
import OneDriveInputLayout from "./InputLayout/OneDriveInputLayout";
import OutlookInputLayout from "./InputLayout/OutlookInputLayout";

const TabContent = ({ source, activeTab, onFieldChange }) => {
  if (source === "Gmail") {
    if (activeTab === "Input") {
      return <GmailInputLayout onFieldChange={onFieldChange} />;
    } else if (activeTab === "Widget") {
      return <GmailWidgetLayout onFieldChange={onFieldChange} />;
    }
  }
  if (source === "GoogleDocs") {
    if (activeTab === "Input") {
      return <GoogleDocInputLayout onFieldChange={onFieldChange} />;
    } else if (activeTab === "Widget") {
      return <GoogleDocWidgetLayout onFieldChange={onFieldChange} />;
    } 
  }
  if (source === "GoogleSheets") {
    if (activeTab === "Input") {
      return <GoogleSheetInputLayout onFieldChange={onFieldChange} />;
    } else if (activeTab === "Widget") {
      return <GoogleSheetWidgetLayout onFieldChange={onFieldChange} />;
    }
  }
  if (source === "OneDrive") {
    if (activeTab === "Input") {
      return <OneDriveInputLayout onFieldChange={onFieldChange} />;
    } else if (activeTab === "Widget") {
      return <GoogleSheetWidgetLayout onFieldChange={onFieldChange} />;
    }
  }
  if (source === "OneNote") {
    if (activeTab === "Input") {
      return <OneNoteInputLayout onFieldChange={onFieldChange} />;
    } else if (activeTab === "Widget") {
      return <GoogleSheetWidgetLayout onFieldChange={onFieldChange} />;
    }
  }

  if (source === "Outlook") {
    if (activeTab === "Input") {
      return <OutlookInputLayout onFieldChange={onFieldChange} />;
    } else if (activeTab === "Widget" ) {
      return <GoogleSheetWidgetLayout onFieldChange={onFieldChange} />;
    }
  }
  
  // Handle other sources similarly...
  return null;
};

export default TabContent;
