import React from 'react';
import { Tooltip } from 'react-tooltip';

const InfoIcon = ({ message }) => {
  return (
    <div className="relative flex items-center">
      <span
        className="text-blue-500 cursor-pointer"
        data-tooltip-id="infoTooltip" // Updated to match the new API
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M10 2a8 8 0 100 16A8 8 0 0010 2zm0 2a1 1 0 011 1v7a1 1 0 01-2 0V5a1 1 0 011-1zm1 10a1 1 0 01-2 0h2z"
            clipRule="evenodd"
          />
        </svg>
      </span>
      <Tooltip id="infoTooltip" place="bottom" effect="solid">
        {message}
      </Tooltip>
    </div>
  );
};

export default InfoIcon;
