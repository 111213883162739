import React, { useState, useEffect, useCallback } from "react";
import FormInputSource from "../../../Ui/Input/FormInputSource";
import SelectInput from "../../../Ui/Select/SelectInput";
import InfoIcon from "../../../Ui/Info/InfoIcon";

const ApplePodcastsInputLayout = ({ onFieldChange }) => {
  const [appleConfig, setAppleConfig] = useState({
    youtubeUrls: "",
    description: "",
    episodeOption: "latest", // default to "latest"
    episodeCount: "5", // default to "5" for previous episodes
  });

  const handleFieldChange = useCallback(onFieldChange, [onFieldChange]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type === "radio") {
      setAppleConfig((prevConfig) => ({ ...prevConfig, episodeOption: value }));
    } else {
      setAppleConfig((prevConfig) => ({ ...prevConfig, [name]: value }));
    }
  };

  useEffect(() => {
    if (handleFieldChange) {
      handleFieldChange("rssurl", appleConfig);
    }
  }, [appleConfig, handleFieldChange]);

  return (
    <div className="flex flex-col p-5 bg-white inner-page-bg-input">
      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-normal  px-1  pb-3 uppercase tracking-wider flex items-center">
          Podcast Page
          <InfoIcon message="Each episode will be fetched as an audio file, together with a text file containing the title and description of the episode." />
        </label>
        <textarea
          name="youtubeUrls"
          value={appleConfig.youtubeUrls}
          onChange={handleChange}
          className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
          rows="4"
          placeholder="Enter one URL per line"
        />
      </div>

      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-normal px-1 pb-5 uppercase tracking-wider flex items-center">
          Episode Option
        </label>
        <div className="flex flex-col gap-3">
          <label className="flex items-center mb-2">
            <input
              type="radio"
              id="latest"
              name="episodeOption"
              value="latest"
              checked={appleConfig.episodeOption === "latest"}
              onChange={handleChange}
              className="mr-2 w-5 h-5 cursor-pointer"
            />
          <span className="mx-1 text-black text-base font-light">
          Latest episode only   </span>  
          </label>

          <label className="flex items-center">
            <input
              type="radio"
              id="previous"
              name="episodeOption"
              value="previous"
              checked={appleConfig.episodeOption === "previous"}
              onChange={handleChange}
              className="mr-2 w-5 h-5 cursor-pointer"
            />
            <span className="mx-1 text-black text-base font-light">
            Episodes from previous </span>
          </label>
          <div className="flex flex-col items-start gap-4 mt-8">
            <label className="block text-gray-700 text-sm font-normal px-1  pb-0 uppercase tracking-wider ">
              Maximum no. of episodes
            </label>
            <SelectInput
              name="episodeCount"
              value={appleConfig.episodeCount}
              options={[
                { value: "5", label: "5" },
                { value: "10", label: "10" },
                { value: "20", label: "20" },
              ]}
              onChange={handleChange}
              className="w-full"
              disabled={appleConfig.episodeOption !== "previous"}
            />
          </div>
        </div>
      </div>

      <div className="mb-6 mt-3">
        <FormInputSource
          label="Description"
          type="text"
          name="description"
          value={appleConfig.description}
          onChange={handleChange}
          className="w-full"
        />
      </div>
    </div>
  );
};

export default ApplePodcastsInputLayout;
