import React from "react";
import GoogleIcon from "../../assets/images/google-icon.webp";
import { Link } from "react-router-dom";
import Button from "../Common/Button/Button";
import FormInput from "../Common/FormInput/FormInput";
import Logo from "../Common/Logo/Logo";
import LeftPanel from "../Inner/LeftPanel";

const Login = () => {
  return (
    <div className="flex justify-center md:items-center bg-white relative min-h-screen bg-img px-2">
      <div className="w-full max-w-7xl mx-auto md:flex">
        <div className="md:flex w-full items-start place-content-center py-10 lg:gap-20">
        <LeftPanel />
          <div
            className="md:w-[45%] w-full flex flex-col items-center relative bg-white p-4 md:px-12 md:py-10 rounded-lg"
          >
            <div className="md:hidden block md:my-0 mt-6 mb-9">
            <Logo isMobile />
            </div>
            
            <h1 className="text-2xl md:text-3xl text-start w-full font-semibold">
              Login to your account!
            </h1>
            <p className="text-start text-sm mb-8 py-1 block w-full text-gray-400">
              Login into your details
            </p>
            <div className="w-full">
              <Button className="text-black border-2 border-slate-200 w-full shadow-none" icon={GoogleIcon}>
                Continue with Google
              </Button>
            </div>
            <div className="flex place-content-center items-center my-1">
              <div className="border-b bg-grey-100 md:w-38 lg:w-48 w-28"></div>
              <div className="text-base p-3 text-slate-300">Or</div>
              <div className="border-b bg-grey-100 md:w-38 lg:w-48 w-28"></div>
            </div>
            <form className="w-full">
              <div className="mb-4">
               <FormInput label="Work Email" type="email" required />
              </div>
              <div className="mb-4">
               <FormInput label="Password" type="password" required />
              </div>
              <div className="flex my-4 items-end">
                <p className="px-1 text-sm text-slate-500 text-end w-full underline">
                  <Link to="/forgot-password" className="text-blue-400 px-1">
                    Forgot Password
                  </Link>
                </p>
              </div>
              <Link to="/workflows">
                <Button className="mt-7 bg-blue-500 text-white p-2 h-10 w-full">
                  Sign in
                </Button>
              </Link>
            </form>
            <p className="text-center text-sm mt-4 py-3 block w-full text-gray-400">
              Don't have an account?
              <Link to="/register" className="text-blue-400 px-1">
                Sign Up
              </Link>
            </p>
          </div>
        </div>
      </div> 
    </div>
  );
};

export default Login;
