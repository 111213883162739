import React, { useState } from "react";

const TagsInput = ({ tags, setTags }) => {
  const [inputValue, setInputValue] = useState("");

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim()) {
      e.preventDefault();
      if (!tags.includes(inputValue.trim())) {
        setTags([...tags, inputValue.trim()]);
        setInputValue("");
      }
    }
  };

  const removeTag = (index) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  return (
    <div className="flex flex-wrap gap-2 ">
      {tags.map((tag, index) => (
        <div
          key={index}
          className="flex items-center bg-blue-500 text-white px-3 py-1 rounded-full"
        >
          {tag}
          <button
            type="button"
            onClick={() => removeTag(index)}
            className="ml-2 text-sm text-white"
          >
            ×
          </button>
        </div>
      ))}
      <input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        className="w-full p-2.5 h-11 border border-slate-300 
         focus:outline-none focus:shadow-outline rounded-md text-sm font-normal shadow-sm"
        placeholder="Type and hit enter to add tag"
      />
    </div>
  );
};

export default TagsInput;
